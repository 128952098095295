import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IMapPoint, IMapPolyline } from './map.model';
import { INIT_POSITION, INIT_ZOOM } from 'src/app/libs/constants/constants';

export interface IMapState {
  isInitialized: boolean;
  centerPoint: IMapPoint;
  zoom: number;
  points: IMapPoint[];
  speedPoint: IMapPoint;
  lines: IMapPolyline[];
  focusedVehicleId: number;
  selectedVehicleIds: number[];
}

export function createInitialState(): IMapState {
  return {
    isInitialized: false,
    centerPoint: INIT_POSITION,
    zoom: INIT_ZOOM,
    speedPoint: null,
    points: [],
    lines: [],
    focusedVehicleId: null,
    selectedVehicleIds: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'map' })
export class MapStore extends Store<IMapState> {
  public constructor() {
    super(createInitialState());
  }
}

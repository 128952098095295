import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app.config.js';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class AppConfigService {
  public configuration: AppConfig;

  constructor(private http: HttpClient) { }

  loadAppConfig(): Promise<any> {
    return this.http.get('/assets/configs/config.json')
      .toPromise()
      .then((data: AppConfig) => {
        this.configuration = data;
      });
  }
}

import { Component, OnInit, Injector } from '@angular/core';
import { UrlRoutes } from 'src/app/app-routes';
import { CommonVehicleComponent } from '../common/common-vehicle.component';

@Component({
  selector: 'app-vehicle-detail',
  templateUrl: './vehicle-detail.component.html',
  styleUrls: ['./vehicle-detail.component.scss']
})
export class VehicleDetailComponent extends CommonVehicleComponent implements OnInit {
  menuItems = [
    {
      name: 'vehicles.information',
      route: UrlRoutes.urls.vehicleInfoUrl,
    },
    {
      name: 'page-title.drive-book',
      route: UrlRoutes.urls.vehicleDrivebookUrl,
    },
    {
      name: 'vehicles.show-on-map',
      route: UrlRoutes.urls.vehicleMapUrl,
    },
  ];

  constructor(injector: Injector) {
    super(injector);
    this.subscriptions.add(this.vehicleQuery.allCars$.subscribe((vehicles) =>
      this.setActiveVehicle(this.onActiveVehicle, this.onErrorActiveVehicle)
    ));
  }

  ngOnInit(): void {
  }

  onActiveVehicle = (): void => {
    this.setTitle('vehicles.overview', this.activeVehicle);
  }

  onErrorActiveVehicle = (): void => {
  }
}

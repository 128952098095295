export interface IGroupDTO {
  groupID: number;
  name: string;
  root: number;
}

export interface ICarPositionDTO {
  longitude: number;
  latitude: number;
}

export interface ICarPositionExtra extends ICarPositionDTO {
  carId: number;
  previousPositions: ICarPositionDTO;
  lastKnownDate: string;
  isOnline: boolean;
  speed: number;
  lastKnownPositionAdress: string;
  isKeyOn: boolean;
}

export interface ICarPositions {
  positions: ICarPositionDTO[];
}

export interface ICarDTO {
  carID: number;
  spz: string;
  speed: number;
  toolTipText: string;
  position: ICarPositionDTO;
  positionData?: ICarPositionExtra;
  lastKnownPositionAdress: string;
  lastKnownDate: string;
  type: CarType;
  typeKeyVal: string;
  reminders: any[];
  activeReminders: IUpcomingReminderDTO[];
  isLastKeyOnTime: boolean;
}

export interface ICarReminderDTO {
  name: string;
  type: number;
}

export interface IUpcomingReminderDTO {
  id: string;
  reminderId: string;
  reminderTitle: string;
  reminderType: number;
  level: number;
  nextDate: Date;
  nextTachometer: number;
}

export interface ICarGroupDTO {
  group: IGroupDTO;
  cars: ICarDTO[];
}

export interface ICarGroupsDTO {
  carGroups: ICarGroupDTO[];
}

export interface ICarLastPositionDTO {
  carId: number;
  address: string;
  timeOfDrive: string;
}
export interface ICarColorDTO {
  nCarColorID: number;
  strName: string;
  keyVal: string;
}

export interface ICarFuelDTO {
  nFuelID: number;
  strName: string;
  keyVal: string;
}

export interface ICarConciseDTO {
  carId: number;
  carColor: ICarColorDTO;
  fuel: ICarFuelDTO;
  deviceId: number;
  carColorId: number;
  fuelId: number;
  carBodyId: number;
  carBody: ICarBodyDTO;
  spz: string;
  entitySerialNumber: string;
  note: string;
  toolTipText: string;
  ccsCardNumber: string;
}

export interface IPutCarConciseDTO {
  carId: number;
  deviceId: number;
  carColorId: number;
  fuelId: number;
  carBodyId: number;
  spz: string;
  entitySerialNumber: string;
  note: string;
  toolTipText: string;
  ccsCardNumber: string;
}

export interface ICarDriverDTO {
  nCarID: number;
  nPersonID: number;
  strName: string;
  strSurname: string;
}

export interface ICarDriversDTO {
  carId: number;
  drivers: {
    drivers: ICarDriverDTO[];
  };
}

export interface ICarBodyDTO {
  nCarBodyID: number;
  strName: string;
  keyVal: string;
}

export interface ICarBodiesDTO {
  carBodies: ICarBodyDTO[];
}

export interface ICarColorDTO {
  nCarColorID: number;
  strName: string;
  keyVal: string;
}

export interface ICarColorsDTO {
  carColors: ICarColorDTO[];
}

export interface ICarFuelDTO {
  nFuelID: number;
  strName: string;
  keyVal: string;
}

export interface ICarFuelsDTO {
  fuels: ICarFuelDTO[];
}

export interface ICarBodyDTO {
  nCarBodyID: number;
  strName: string;
  keyVal: string;
}

export interface ICarBodiesDTO {
  carBodies: ICarBodyDTO[];
}

export interface ICarsCurrentPosition {
  lastPositions: ICarCurrentPosition[];
  lastTickTime: number;
}

export interface ICarCurrentPosition {
  carId: number;
  lastKnownDate: string;
  longitude: number;
  latitude: number;
  isKeyOn: boolean;
  driverName: string;
  direction: number;
  speed: number;
}

export interface ICarLastTachoValueDTO {
  carId: number;
  tachoValue: number;
  timeOfDrive: string;
}

export enum CarType {
  CAR = 'CAR',
  SUV = 'SUV',
  TRANSPORTER = 'TRANSPORTER',
  DELIVERY_TRUCK = 'DELIVERY_TRUCK',
  BUS = 'BUS',
  TRACTOR = 'TRACTOR',
  TRUCK = 'TRUCK',
  CONSTRUCTION_MACHINE = 'CONSTRUCTION_MACHINE',
  BIKE = 'BIKE',
  LOCATOR = 'LOCATOR',
  BOAT = 'BOAT',
}

export enum VehicleStateEnum {
  KEY_ON_SPEED_ON_DATA_ON = 'KEY_ON_SPEED_ON_DATA_ON',
  KEY_ON_SPEED_ON_DATA_OFF = 'KEY_ON_SPEED_ON_DATA_OFF',
  KEY_OFF_SPEED_ON_DATA_ON = 'KEY_OFF_SPEED_ON_DATA_ON',
  KEY_OFF_SPEED_ON_DATA_OFF = 'KEY_OFF_SPEED_ON_DATA_OFF',
  KEY_ON_SPEED_OFF_DATA_ON = 'KEY_ON_SPEED_OFF_DATA_ON',
  KEY_ON_SPEED_OFF_DATA_OFF = 'KEY_ON_SPEED_OFF_DATA_OFF',
}

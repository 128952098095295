import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { SiteLayoutComponent } from './layouts/site-layout/site-layout.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { VehicleDetailComponent } from './components/vehicle-detail/vehicle-detail.component';
import { VehicleMapComponent } from './components/vehicle-map/vehicle-map.component';
import { VehicleInfoComponent } from './components/vehicle-info/vehicle-info.component';
import { VehicleDrivebookComponent } from './components/vehicle-drivebook/vehicle-drivebook.component';
import { VehicleDrivebookFilterComponent } from './components/vehicle-drivebook-filter/vehicle-drivebook-filter.component';
import { DrivebookDetailComponent } from './components/drivebook-detail/drivebook-detail.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ForgotPasswordSetComponent } from './components/forgot-password-set/forgot-password-set.component';
import { AuthService } from './store/auth/auth.service';
import { Observable } from 'rxjs';
import { AuthHttpService } from './store/auth/auth-http.service';

@Injectable()
export class AppDataResolver implements Resolve<any> {
  constructor(private provider: AuthService, private authHttpService: AuthHttpService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.provider.loadAuthData(this.authHttpService);
  }
}
const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: 'login', component: LoginLayoutComponent, children: [
      { path: '', component: LoginComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'forgot-password-set/:token', component: ForgotPasswordSetComponent },
    ]
  },
  {
    path: 'dashboard',
    component: SiteLayoutComponent,
    resolve: { data: AppDataResolver },
    children: [
      { path: '', component: DashboardComponent },
      { path: 'map', component: VehicleMapComponent },
      { path: ':vehicleId', component: VehicleDetailComponent },
      { path: ':vehicleId/map', component: VehicleMapComponent },
      { path: ':vehicleId/info', component: VehicleInfoComponent },
      { path: ':vehicleId/drivebook', component: VehicleDrivebookComponent },
      { path: ':vehicleId/drivebook/filter', component: VehicleDrivebookFilterComponent },
      { path: ':vehicleId/drivebook/detail/:drivebookId', component: DrivebookDetailComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Pipe, PipeTransform } from '@angular/core';
import { ICarDTO, ICarPositionExtra, CarType } from 'src/app/store/vehicle/vehicle.model';

@Pipe({
  name: 'vehicleIcon',
  pure: true
})
export class VehicleIconPipe implements PipeTransform {

  public transform(value: number, vehicle: ICarDTO, isOnline: boolean): string {
    if (!value || !vehicle) {
      return;
    }
    const path = 'assets/images/';
    switch (vehicle?.type) {
      case CarType.CAR:
        return `${ path }${ isOnline ? 'car-online.png' : 'car.png' }`;
      case CarType.SUV:
        return `${ path }${ isOnline ? 'suv-online.png' : 'suv.png' }`;
      case CarType.DELIVERY_TRUCK:
        return `${ path }${ isOnline ? 'delivery-truck-online.png' : 'delivery-truck.png' }`;
      case CarType.BIKE:
        return `${ path }${ isOnline ? 'bike-online.png' : 'bike.png' }`;
      case CarType.BOAT:
        return `${ path }${ isOnline ? 'boat-online.png' : 'boat.png' }`;
      case CarType.BUS:
        return `${ path }${ isOnline ? 'bus-online.png' : 'bus.png' }`;
      case CarType.CONSTRUCTION_MACHINE:
        return `${ path }${ isOnline ? 'construction-machine-online.png' : 'construction-machine.png' }`;
      case CarType.TRANSPORTER:
        return `${ path }${ isOnline ? 'transporter-online.png' : 'transporter.png' }`;
      case CarType.TRUCK:
        return `${ path }${ isOnline ? 'truck-online.png' : 'truck.png' }`;
      case CarType.LOCATOR:
        return `${ path }${ isOnline ? 'locator-online.png' : 'locator.png' }`;
      case CarType.TRACTOR:
        return `${ path }${ isOnline ? 'tractor-online.png' : 'tractor.png' }`;
      default:
        return `${ path }${ isOnline ? 'car-online.png' : 'car.png' }`;
    }
  }
}

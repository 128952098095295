import { Component, OnInit, Injector } from '@angular/core';
import { ICarGroupDTO, ICarDTO } from 'src/app/store/vehicle/vehicle.model';
import { UrlRoutes } from 'src/app/app-routes';
import { CommonVehicleComponent } from '../common/common-vehicle.component';
import { interval } from 'rxjs';
import { MapService } from 'src/app/store/map/map.service';
import { MapQuery } from 'src/app/store/map/map.query';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends CommonVehicleComponent implements OnInit {

  vehicleGroups: ICarGroupDTO[];
  menuItems = [];
  selectedVehicleIds: number[];
  mapService: MapService;
  mapQuery: MapQuery;

  public constructor(injector: Injector) {
    super(injector);
    this.mapService = injector.get(MapService);
    this.mapQuery = injector.get(MapQuery);
    this.subscriptions.add(this.vehicleQuery.carGroups$.subscribe((vehicleGroups) => this.vehicleGroups = vehicleGroups));
    this.subscriptions.add(this.mapQuery.selectedVehicleIds$.subscribe((selectedVehicleIds) =>
      this.selectedVehicleIds = selectedVehicleIds)
    );
    this.subscriptions.add(interval(5000).subscribe(() => this.updateTriger = new Date().getMilliseconds()));
    this.menuItems = [
      {
        name: 'vehicles.show-on-map',
        callback: this.selectSingleVehicle,
      },
      {
        name: 'page-title.drive-book',
        route: UrlRoutes.urls.vehicleDrivebookUrl,
      },
      {
        name: 'vehicles.information',
        route: UrlRoutes.urls.vehicleInfoUrl,
      },
      {
        name: 'vehicles.navigate-to',
        callback: this.onNavigateToVehicleClick,
      },
    ];
  }

  public ngOnInit(): void {
    this.setTitle('page-title.cars');
  }

  public onVehicleClick(vehicle: ICarDTO): void {
    this.navigate(UrlRoutes.urls.vehicleDetailUrl, vehicle.carID);
  }

  public onNavigateToVehicleClick = (vehicle: ICarDTO): void => {
    let urlAddress = this.utilityService.isAppleDevice(navigator.userAgent) ? UrlRoutes.mapUrls.apple : UrlRoutes.mapUrls.google;
    urlAddress = this.utilityService.format(urlAddress, vehicle?.position?.latitude, vehicle?.position?.longitude);
    window.open(urlAddress, '_blank');
  }

  public selectGroup(group: ICarGroupDTO): void {
    const vehicleIds = group?.cars?.map(car => car.carID);
    this.mapService.selectGroup(vehicleIds);
  }

  public selectVehicle(vehicle: ICarDTO): void {
    this.mapService.selectVehicle(vehicle?.carID);
  }

  public selectSingleVehicle = (vehicle: ICarDTO): void => {
    this.mapService.selectSingleVehicle(vehicle.carID);
    this.onMap();
  }

  public onMap(): void {
    if (!this.selectedVehicleIds || this.selectedVehicleIds?.length === 0) {
      return;
    }
    this.navigateWithQueryParams(UrlRoutes.urls.vehiclesMapUrl, { ids: this.selectedVehicleIds });
  }
}

import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { VehicleStore, IVehicleState } from './vehicle.store';
import { from, of } from 'rxjs';
import { flatMap, map, toArray, distinct } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class VehicleQuery extends Query<IVehicleState> {

  public readonly carGroups$ = this.select((state) => {
    return !!state.carGroups ? state.carGroups.slice().sort((n1, n2) => {
      if (n1?.group?.name > n2?.group?.name) {
        return 1;
      }
      if (n1?.group?.name < n2?.group?.name) {
        return -1;
      }
      return 0;
    }) : [];
  });

  public readonly isLoadingCars$ = this.select((state) => state.loading);
  public readonly vehicleInfo$ = this.select((state) => state.vehicleInfo);
  public readonly vehicleBodies$ = this.select((state) => state.vehicleBodies);
  public readonly vehicleColors$ = this.select((state) => state.vehicleColors);
  public readonly vehicleFuels$ = this.select((state) => state.vehicleFuels);
  public readonly vehicleDrivers$ = this.select((state) => state.vehicleDrivers);
  public readonly vehicleLastTacho$ = this.select((state) => state.vehicleLastTacho);

  public readonly allCars$ = this.select((state) => state.carGroups).pipe(
    flatMap((data) =>  !data ? of(null) : of(data).pipe(
      flatMap((carGroups) => from(carGroups).pipe(
        map((carGroup) => carGroup.cars),
        flatMap((cars) => from(cars))
      )),
      distinct((car) => car.carID),
      toArray()
    ))
  );

  public constructor(protected store: VehicleStore) {
    super(store);
  }
}

import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ICarPositionExtra } from 'src/app/store/vehicle/vehicle.model';

export interface ICarsPositionsState {
  carPositions: ICarPositionExtra[];
  selectedCarsIDs: number[];
  selectedCarsStaticIDs: number[];
}

export function createInitialState(): ICarsPositionsState {
  return {
    carPositions: [],
    selectedCarsIDs: [],
    selectedCarsStaticIDs: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'carsPositions' })
export class CarsPositionStore extends Store<ICarsPositionsState> {

  public constructor() {
    super(createInitialState());
  }
}

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

const DURATION = 4000;
const DURATION_LONG = 10000;
const VERTICAL_POSITION = 'bottom';
const HORIZONTAL_POSITION = 'center';
const CSS_CLASS_INFO = 'snackbar-info';
const CSS_CLASS_ERROR = 'snackbar-error';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  constructor(private snackbar: MatSnackBar) {
  }

  showInfo(text: string) {
    this.snackbar.open(text, 'x', {
      duration: DURATION,
      verticalPosition: VERTICAL_POSITION,
      horizontalPosition: HORIZONTAL_POSITION,
      panelClass: CSS_CLASS_INFO
    });
  }

  showError(text: string) {
    this.snackbar.open(text, 'x', {
      duration: DURATION_LONG,
      verticalPosition: VERTICAL_POSITION,
      horizontalPosition: HORIZONTAL_POSITION,
      panelClass: CSS_CLASS_ERROR
    });
  }
}

<div>
  <mat-list>
    <mat-accordion class="vehicle-group-list"
      [multi]="true">
      <ng-container *ngFor="let group of vehicleGroups">
        <mat-expansion-panel [expanded]="vehicleGroups.length <= 2">
          <mat-expansion-panel-header class="group-headline"
            [collapsedHeight]="'50px'"
            [expandedHeight]="'50px'"
            [ngClass]="{'selected-group': group | isGroupSelected: selectedVehicleIds}">
            <div class="group-header">
              <mat-checkbox color="accent" class="group-checkbox" matListIcon [checked]="group | isGroupSelected: selectedVehicleIds" class="select-checkbox" (click)="$event.stopPropagation();$event.preventDefault();selectGroup(group)"></mat-checkbox>
              <ui-divider [vertical]="true" [compact]="true"></ui-divider>
              <span class="main-header">{{ group?.group?.name }}</span>&nbsp;
              <span class="info">({{ group?.cars?.length }})</span>
            </div>
          </mat-expansion-panel-header>

          <ng-container *ngFor="let vehicle of group?.cars | sortVehicles">
            <div class="vehicle-row-wrapper" [class.selected]="selectedVehicleIds?.includes(vehicle?.carID)">
              <div class="vehicle-row" (click)="selectVehicle(vehicle)">
                <div class="row v-center tooltipText">
                  <mat-checkbox matListIcon [checked]="selectedVehicleIds?.includes(vehicle?.carID)" class="select-checkbox" (click)="$event.stopPropagation();$event.preventDefault();selectVehicle(vehicle)"></mat-checkbox>
                  <ui-divider [vertical]="true" [compact]="true"></ui-divider>
                  <div class="full-width">
                    <b class="spz">{{ vehicle?.spz }}</b>
                    <p class="small light overflowed">{{vehicle?.toolTipText}}</p>
                  </div>
                </div>
                <div class="row v-center">
                  <div class="column-data">
                    <div>
                      <ng-container *ngIf="(vehicle?.lastKnownDate | isVehicleOnline: updateTriger); else zeroSpeed">
                        <b>{{ vehicle?.speed || 0 }} km/h </b>
                      </ng-container>
                      <ng-template #zeroSpeed>
                        0 km/h
                      </ng-template>
                    </div>
                    <p class="small light">{{ vehicle?.lastKnownDate | date:"dd.MM. HH:mm" }}</p>
                  </div>

                  <ui-divider [compact]="true"
                    [vertical]="true"></ui-divider>
                  <img *ngIf="vehicle?.lastKnownDate | isVehicleOnline : updateTriger; else offlineVehicleImage"
                    [src]="vehicle?.carID | vehicleIcon: vehicle : true"
                    class="car-type-image"
                    matTooltipPosition="left"
                    [matTooltip]="vehicle?.typeKeyVal | translate">

                  <ng-template #offlineVehicleImage>
                    <img [src]="vehicle?.carID | vehicleIcon: vehicle : false"
                      class="car-type-image"
                      matTooltipPosition="left"
                      [matTooltip]="vehicle?.typeKeyVal | translate">
                  </ng-template>

                  <ui-divider [vertical]="true"></ui-divider>
                  <mat-icon [matMenuTriggerFor]="options" class="transparent" (click)="$event.stopPropagation();$event.preventDefault();">more_vert</mat-icon>

                  <mat-menu #options="matMenu" x-position="after" y-position="below">
                    <ng-container *ngFor="let menuItem of menuItems">
                      <button *ngIf="!!menuItem?.route"  mat-menu-item (click)="navigate(menuItem.route, vehicle?.carID)">{{ menuItem?.name | translate }}</button>
                      <button *ngIf="!!menuItem?.callback"  mat-menu-item (click)="menuItem?.callback(vehicle)">{{ menuItem?.name | translate }}</button>
                    </ng-container>
                  </mat-menu>
                </div>
              </div>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </mat-list>
  <div class="map-button-container">
    <button mat-fab [ngClass]="selectedVehicleIds?.length > 0 ? 'blue': 'default'" class="map-button" (click)="onMap()">
      <div class="column">
        <div [ngClass]="{'disabled':selectedVehicleIds?.length === 0 }">{{ 'vehicles.map' | translate}}</div>
      </div>
    </button>
  </div>
</div>

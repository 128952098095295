import { Injectable } from '@angular/core';
import { AppConfigService } from './appConfig.service';

@Injectable({
  providedIn: 'root'
})

export class LoggerService {
  constructor(private appConfigService: AppConfigService) { }
  public logInfo(message: string, object?: any) {
    if (this.appConfigService.configuration.enableLogging) {
      if (!!object) {
        // tslint:disable-next-line:no-console
        console.log(message, object);
      } else {
        // tslint:disable-next-line:no-console
        console.log(message);
      }
    }
  }
  public logError(message, object?: any) {
    if (this.appConfigService.configuration.enableLogging) {
      if (!!object) {
        // tslint:disable-next-line:no-console
        console.error(message, object);
      } else {
        // tslint:disable-next-line:no-console
        console.error(message);
      }
    }
  }
  public logWarning(message, object?: any) {
    if (this.appConfigService.configuration.enableLogging) {
      if (!!object) {
        // tslint:disable-next-line:no-console
        console.warn(message, object);
      } else {
        // tslint:disable-next-line:no-console
        console.warn(message);
      }
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { ICarGroupDTO } from 'src/app/store/vehicle/vehicle.model';

@Pipe({
  name: 'isGroupSelected',
  pure: true
})
export class IsGroupSelectedPipe implements PipeTransform {

  public transform(group: ICarGroupDTO, selectedCarIds: number[]): boolean {
    return group?.cars.map(car => (car.carID)).every(id => selectedCarIds?.includes(id));
  }
}

import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IDriveBookFilter, IDriveBookTotalsDTO, IDriveBookDayDTO, IDriveBookDTO, IDriveBookPathDTO } from './drive-book.model';
import { ICarDriverDTO } from '../vehicle/vehicle.model';

export interface IDriveBookState {
  filter?: IDriveBookFilter;
  totals?: IDriveBookTotalsDTO;
  drivebooks?: IDriveBookDayDTO[];
  drivebookDetail?: IDriveBookDTO;
  drivebookPath?: IDriveBookPathDTO;
  selectedDrivebookDay?: IDriveBookDayDTO;
  drivers?: ICarDriverDTO[];
}

export function createInitialState(): IDriveBookState {
  return {};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'drive-book' })
export class DriveBookStore extends Store<IDriveBookState> {

  public constructor() {
    super(createInitialState());
  }

}


export function fetchCarGroups(baseUrl: string) { return `${ baseUrl }/api/Car/GroupHierarchy`; }
export function fetchCarConcise(baseUrl: string, carID: number) { return `${ baseUrl }/api/Car/Concise/${ carID }`; }
export function fetchCarLastAddress(baseUrl: string, carID: number) { return `${ baseUrl }/api/Car/LastAddress/${ carID }`; }
export function fetchDriversByCarId(baseUrl: string, carID: number) { return `${ baseUrl }/api/Car/Drivers/${ carID }`; }
export function fetchCarColors(baseUrl: string) { return `${ baseUrl }/api/CarProperties/GetCarColors`; }
export function fetchCarFuels(baseUrl: string) { return `${ baseUrl }/api/CarProperties/GetFuels`; }
export function fetchCarBodies(baseUrl: string) { return `${ baseUrl }/api/CarProperties/GetBodies`; }
export function fetchCarLastTachoValue(baseUrl, carID: number) {return `${ baseUrl }/api/Car/LastTachoValue/${carID}`; }
export function fetchCarsPositionsByUserID(baseUrl, lastQueryTime: number) {return `${ baseUrl }/api/Car/CarsPositionsByUserID/${lastQueryTime}`; }

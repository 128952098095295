import { Injectable } from '@angular/core';
import { AppStore, } from './app.store';
import { Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class AppService {
  public constructor(
    private appStore: AppStore,
    private titleService: Title,
  ) { }

  public setIsLoading(isLoading: boolean): void {
    if (isLoading) {
      this.appStore.update((state) => ({...state, pendingRequests: state.pendingRequests + 1 }));
    } else {
      this.appStore.update((state) => ({...state, pendingRequests: state.pendingRequests - 1 }));
    }
  }

  public setTitle(title: string, subtitle?: string): void {
    this.appStore.update({ title, subtitle });
    this.titleService.setTitle(title);
  }
}

export const MIN_SPEED_LEVEL = 3;
export const MAX_SPEED_LEVEL = 300;
export const INIT_ZOOM = 8;
export const SPEED_LIMIT_TOWN = 50;
export const SPEED_LIMIT_ROADS = 70;
export const STROKE_OPACITY = 0.8;
export const STROKE_WEIGHT = 2;
export const FILL_OPACITY = 0.35;
export const INIT_POSITION = {
  latitude: 49.80376388888889,
  longitude: 15.474913888888889
};

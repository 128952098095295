import { Component, OnInit, Injector } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { UrlRoutes } from 'src/app/app-routes';
import { AuthService } from 'src/app/store/auth/auth.service';
import { IUserSetChangePassword } from 'src/app/store/auth/auth.model';
import { CommonComponent } from '../common/common.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password-set.component.html',
  styleUrls: ['./forgot-password-set.component.scss'],
})
export class ForgotPasswordSetComponent extends CommonComponent implements OnInit {
  public token: string;

  constructor(private authService: AuthService, injector: Injector) {
    super(injector);
    if (this.isLoggedIn) {
      this.navigate(UrlRoutes.urls.dashboardUrl);
    }
  }

  ngOnInit() {
    this.token = this.getParam('token');
    this.initializeForm();
    this.setTitle('forgot-password-set.title');
  }

  private initializeForm(): void {
    this.createForm({
      newPassword: new FormControl(null, [Validators.required, Validators.minLength(6)]),
      newPasswordConfirm: new FormControl(null, [Validators.required, Validators.minLength(6)]),
    });
  }

  public changeFormValue(name: string, value: string): void {
    this.form.controls[name].patchValue(value);
  }

  get arePasswordsEqual() {
    return this.form.value.newPassword === this.form.value.newPasswordConfirm;
  }

  public setPassword(): void {
    const request: IUserSetChangePassword = {
      newPassword: this.form.value.newPassword,
      token: this.token,
    };
    this.authService.sendSetForgotPassword(request, () => {
      this.showInfo(this.getTranslatedKey('forgot-password-set.change-successfull'));
      this.navigate(UrlRoutes.urls.loginUrl);
    }, () => {
      this.showError(this.getTranslatedKey('forgot-password-set.change-error'));
    });
  }

  public back(): void {
    this.navigate(UrlRoutes.urls.loginUrl);
  }
}

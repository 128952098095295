import { Component, OnInit, Injector } from '@angular/core';
import { CommonVehicleComponent } from '../common/common-vehicle.component';
import { DriveBookQuery } from 'src/app/store/drive-book/drive-book.query';
import { DriveBookService } from 'src/app/store/drive-book/drive-book.service';
import { IDriveBookFilter, DriveType } from 'src/app/store/drive-book/drive-book.model';
import { UrlRoutes } from 'src/app/app-routes';
import { FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-vehicle-drivebook-filter',
  templateUrl: './vehicle-drivebook-filter.component.html',
  styleUrls: ['./vehicle-drivebook-filter.component.scss']
})
export class VehicleDrivebookFilterComponent extends CommonVehicleComponent implements OnInit {

  drivebookQuery: DriveBookQuery;
  drivebookService: DriveBookService;
  drivers: any[];
  filter: IDriveBookFilter;
  driveTypes = DriveType;
  formFilter: FormGroup;

  constructor(injector: Injector) {
    super(injector);
    this.drivebookQuery = injector.get(DriveBookQuery);
    this.drivebookService = injector.get(DriveBookService);
    this.subscriptions.add(this.vehicleQuery.allCars$.subscribe(() =>
      this.setActiveVehicle(this.onActiveVehicle, this.onErrorActiveVehicle)
    ));
    this.subscriptions.add(this.drivebookQuery.filter$.subscribe((filter) => this.filter = filter));
    if (!this.filter) {
      this.drivebookService.createInitialFilter();
    }
  }

  ngOnInit(): void {
    this.createForm({
      intervalFrom: [this.filter.intervalFrom, Validators.required],
      intervalTo: [this.filter.intervalTo, Validators.required],
      type: [this.filter.type, Validators.required],
      driver: [this.filter.driver]
    });
  }

  onActiveVehicle = (): void => {
    this.setTitle('drive-book.filter');
  }

  onErrorActiveVehicle = (): void => {
  }

  onFilterDrivebooks(): void {
    const filter: IDriveBookFilter = {
      intervalFrom: moment(this.form.value.intervalFrom).toISOString(),
      intervalTo: moment(this.form.value.intervalTo).toISOString(),
      type: this.form.value.type,
      driver: this.form.value.driver,
    };
    this.drivebookService.updateFilter(filter);
    this.navigateWithQueryParams(UrlRoutes.urls.vehicleDrivebookUrl, filter, this.vehicleId);
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { WorkspaceService } from './store/workspace/workspace.service';
import { AuthService } from './store/auth/auth.service';
import { CarsPositionService } from './store/cars-position/cars-position.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ommobile';

  private workingOnlineData = true;
  private lastQueryTime: number = 0;

  public constructor(
    private workspaceService: WorkspaceService,
    private authService: AuthService,
    private carsPositionService: CarsPositionService,

  ) { }

  ngOnInit(): void {
    this.workspaceService.getWorkspace();
    window.addEventListener('resize', () => {
      this.setHeightVariable();
    });
    this.setHeightVariable();
    this.updateOnlinePositions();
  }

  setHeightVariable(): void {
    const el = document.querySelector(':root') as HTMLElement;
    el.style.setProperty('--vh', window.innerHeight / 100 + 'px');
  }

  private updateOnlinePositions() {

    if (this.workingOnlineData) {
      setTimeout(() => {
        if (this.authService.isLoggedIn()) {
          console.log('updateOnlinePositions');
          if (!this.carsPositionService.getNewPositionsFromServer(this.lastQueryTime).subscribe(newLastQueryTime => {
            this.lastQueryTime = newLastQueryTime;
            this.updateOnlinePositions();
          })) {
            this.updateOnlinePositions();
          }
        }
        else {
          this.updateOnlinePositions();
        }
      }, 1000);
    }
  }

  ngOnDestroy(): void {
    this.workingOnlineData = false;
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as VEHICLE_ENDPOINTS from './vehicle.endpoints';
import {
  ICarGroupsDTO,
  ICarLastPositionDTO,
  ICarDriversDTO,
  ICarConciseDTO,
  ICarColorDTO,
  ICarColorsDTO,
  ICarFuelDTO,
  ICarFuelsDTO,
  ICarBodyDTO,
  ICarBodiesDTO,
  ICarLastTachoValueDTO,
  ICarsCurrentPosition
} from './vehicle.model';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/services/appConfig.service';

@Injectable({ providedIn: 'root' })
export class VehicleHttpService {

  public constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient
  ) { }

  public fetchCarGroups(): Observable<ICarGroupsDTO> {
    return this.http.get<ICarGroupsDTO>(VEHICLE_ENDPOINTS.fetchCarGroups(this.appConfigService.configuration.API_URL));
  }

  public fetchCarConcise(carID: number): Observable<ICarConciseDTO> {
    return this.http.get<ICarConciseDTO>(VEHICLE_ENDPOINTS.fetchCarConcise(this.appConfigService.configuration.API_URL, carID));
  }

  public fetchCarLastAddress(carID: number): Observable<ICarLastPositionDTO> {
    return this.http.get<ICarLastPositionDTO>(VEHICLE_ENDPOINTS.fetchCarLastAddress(this.appConfigService.configuration.API_URL, carID));
  }

  public fetchDriversByCarId(carID: number): Observable<ICarDriversDTO> {
    return this.http.get<ICarDriversDTO>(VEHICLE_ENDPOINTS.fetchDriversByCarId(this.appConfigService.configuration.API_URL, carID));
  }

  public fetchColors(): Observable<ICarColorDTO[]> {
    return this.http.get<ICarColorsDTO>(VEHICLE_ENDPOINTS.fetchCarColors(this.appConfigService.configuration.API_URL)).pipe(
      map((response) => response.carColors)
    );
  }

  public fetchFuels(): Observable<ICarFuelDTO[]> {
    return this.http.get<ICarFuelsDTO>(VEHICLE_ENDPOINTS.fetchCarFuels(this.appConfigService.configuration.API_URL)).pipe(
      map((response) => response.fuels)
    );
  }

  public fetchCarBodies(): Observable<ICarBodyDTO[]> {
    return this.http.get<ICarBodiesDTO>(VEHICLE_ENDPOINTS.fetchCarBodies(this.appConfigService.configuration.API_URL)).pipe(
      map((response) => response.carBodies)
    );
  }

  public fetchCarLastTachoValue(carID: number): Observable<ICarLastTachoValueDTO> {
    return this.http.get<ICarLastTachoValueDTO>(VEHICLE_ENDPOINTS.fetchCarLastTachoValue(this.appConfigService.configuration.API_URL,
      carID));
  }

  public fetchCarsPositionsByUserID(lastQueryTime: number): Observable<ICarsCurrentPosition> {
    return this.http.get<ICarsCurrentPosition>(VEHICLE_ENDPOINTS.fetchCarsPositionsByUserID(this.appConfigService.configuration.API_URL, lastQueryTime));
  }
}

import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { DriveBookStore, IDriveBookState } from './drive-book.store';

@Injectable({ providedIn: 'root' })
export class DriveBookQuery extends Query<IDriveBookState> {
  public readonly filter$ = this.select((state) => state.filter);
  public readonly totals$ = this.select((state) => state.totals);
  public readonly drivebooks$ = this.select((state) => state.drivebooks);
  public readonly drivebookDetail$ = this.select((state) => state.drivebookDetail);
  public readonly drivebookPath$ = this.select((state) => state.drivebookPath);
  public readonly selectedDrivebookDay$ = this.select((state) => state.selectedDrivebookDay);
  public readonly drivers$ = this.select((state) => state.drivers);

  public constructor(protected store: DriveBookStore) {
    super(store);
  }
}

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as AUTH_ENDPOINTS from './auth.endpoints';
import { ILoginUser } from './auth.model';
import { IUserInfoDTO, IUserSetChangePassword, IUserChangePassword, IUserContactDataRequest } from './auth.model';
import { AppConfigService } from 'src/app/services/appConfig.service';

@Injectable({
  providedIn: 'root'
})

export class AuthHttpService {

  private headers: HttpHeaders;

  constructor(private http: HttpClient, private appConfigService: AppConfigService) {
    this.headers = new HttpHeaders();
    this.headers.append('Content-Type', 'application/json');
  }

  public refreshToken(): any {
    return this.http.post(AUTH_ENDPOINTS.refreshTokenUrl(this.appConfigService.configuration.API_URL), { headers: this.headers });
  }

  public loadAuthData(token): Observable<IUserInfoDTO> {
    const headers = new HttpHeaders();
    headers.append('Authorization', `Bearer ${ token }`);
    return this.http.get<IUserInfoDTO>(AUTH_ENDPOINTS.authDataUrl(this.appConfigService.configuration.API_URL), { headers });
  }

  public loginUser(payload: ILoginUser): any {
    return this.http.post(AUTH_ENDPOINTS.loginUserUrl(this.appConfigService.configuration.API_URL), payload, { headers: this.headers });
  }

  public sendForgotPassword(username: string): any {
    return this.http.post(AUTH_ENDPOINTS.forgotPasswordUrl(this.appConfigService.configuration.API_URL),
      { username }, { headers: this.headers });
  }

  public setForgotPassword(payload: IUserSetChangePassword): any {
    return this.http.post(AUTH_ENDPOINTS.setForgotPasswordUrl(this.appConfigService.configuration.API_URL),
      payload, { headers: this.headers });
  }

  public sendContactData(payload: IUserContactDataRequest): any {
    return this.http.post(AUTH_ENDPOINTS.sendContactDataUrl(this.appConfigService.configuration.API_URL),
      payload, { headers: this.headers });
  }

  public fetchContactData(): Observable<IUserInfoDTO> {
    return this.http.get<IUserInfoDTO>(AUTH_ENDPOINTS.authDataUrl(this.appConfigService.configuration.API_URL), { headers: this.headers });
  }

  public sendChangePassword(payload: IUserChangePassword): any {
    return this.http.post(AUTH_ENDPOINTS.sendChangePasswordUrl(this.appConfigService.configuration.API_URL),
      payload, { headers: this.headers });
  }

  public getWorkspaceByBaseUrl(url: string): Observable<any> {
    return this.http.get(AUTH_ENDPOINTS.getWorkspaceByBaseUrl(this.appConfigService.configuration.API_URL, url), { headers: this.headers });
  }
}

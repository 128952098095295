import { Component, OnInit, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { UrlRoutes } from 'src/app/app-routes';
import { CarsSignalRService } from 'src/app/services/carsSignalR.service';
import { ILoginUser } from 'src/app/store/auth/auth.model';
import { AuthService } from 'src/app/store/auth/auth.service';
import { WorkspaceStore } from 'src/app/store/workspace/workspace.store';
import { CommonComponent } from '../common/common.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends CommonComponent implements OnInit {

  errorLogin = false;

  constructor(
    private authService: AuthService,
    private signalRService: CarsSignalRService,
    injector: Injector,
    private workspaceStore: WorkspaceStore) {
      super(injector);
      if (this.isLoggedIn) {
        this.navigate(UrlRoutes.urls.dashboardUrl);
    }
  }

  ngOnInit() {
    this.createForm({
      username: [null, Validators.required],
      password: [null, Validators.required],
    });
  }

  login() {
    this.errorLogin = false;
    this.setIsLoading(true);
    const user: ILoginUser = {
      username: this.form.controls.username.value,
      password: this.form.controls.password.value,
      workspaceId: this.workspaceStore.getValue().id
    };
    this.authService.loginUser(user, this.onLoginSucceded, this.onLoginError);
  }

  onLoginSucceded = (): void => {
    this.setIsLoading(false);
    this.signalRService.startConnection();
    this.navigate(UrlRoutes.urls.dashboardUrl);
  }

  onLoginError = (error: any): void => {
    this.errorLogin = true;
    this.setIsLoading(false);
  }

  public changeFormValue(name: string, value: string): void {
    this.form.controls[name].patchValue(value);
  }

  public onForgotPassword(): void {
    this.navigate(UrlRoutes.urls.forgotPasswordUrl);
  }
}

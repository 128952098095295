import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'stringToTimestamp',
  pure: false
})
export class StringToTimestampPipe implements PipeTransform {

  public transform(value: string): number {
    const date = moment.utc(value).local();
    return date.valueOf();
  }

}

import { Component, OnInit, Injector } from '@angular/core';
import { UrlRoutes } from 'src/app/app-routes';
import { CommonComponent } from '../common/common.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends CommonComponent implements OnInit {

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    if (this.isLoggedIn) {
      this.navigate(UrlRoutes.urls.dashboardUrl);
    } else {
      this.navigate(UrlRoutes.urls.loginUrl);
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { UtilitiesService } from 'src/app/services/utilities.service';
import * as moment from 'moment';

@Pipe({
  name: 'isVehicleOnline',
  pure: true
})
export class IsVehicleOnlinePipe implements PipeTransform {

  constructor(private utilityService: UtilitiesService) {

  }
  public transform(value: string, trigger?: any): boolean {
    return this.utilityService.getTimeDiffInMinutes(moment().local(), moment.utc(value).local()) < 2;
  }
}

<div>
  <mat-toolbar>
    <div class="toolbar">
      <div class="logo-avatar">
        <img [src]="smallLogoPath"
          alt=""
          (click)="onDashboard()">
        <div class="column">
          <h3>{{ title | translate }}</h3>
          <p *ngIf="!!subtitle"
            class="small light overflowed">{{ subtitle }}</p>
        </div>
      </div>

      <div class="row v-center">
        <mat-icon [matTooltip]="'menu' | translate"
          [matMenuTriggerFor]="actions"
          class="transparent">menu
        </mat-icon>

        <mat-menu #actions="matMenu"
          xPosition="before"
          yPosition="below">        

          <button mat-menu-item
            (click)="onDashboard()">
            <mat-icon>list</mat-icon>
            <span>{{ "page-title.cars" | translate }}</span>
          </button>

          <button mat-menu-item
            (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
            <span>{{ "user-menu.logout" | translate }}</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar>
  <div *ngIf="isLoading">
    <mat-progress-bar mode="indeterminate"
      color="accent"></mat-progress-bar>
    <h3 class="loading-text">LOADING...</h3>
  </div>
</div>

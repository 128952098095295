import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as moment from 'moment';
// tslint:disable-next-line:max-line-length
import { IDriveBooksDTO, IDriveBookFilter, IDriveBookDTO, IDriveBookPathDTO, IPutDriveBookDTO, IDriveBookDetailDTO, ICarDriverDTO, ICarDriversDTO } from './drive-book.model';
import * as DRIVEBOOK_ENDPOINTS from './drive-book.endpoints';
import { AppConfigService } from 'src/app/services/appConfig.service';

@Injectable({ providedIn: 'root' })
export class DriveBookHttpService {

  public constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
  ) { }

  public fetchDriveBooksAndTotals(filter: IDriveBookFilter, vehicleId: number): Observable<IDriveBooksDTO> {
    const body = {
      from: moment(filter?.intervalFrom).startOf('day').format(),
      to: moment(filter?.intervalTo).endOf('day').format(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      type: +filter.type,
      driver: +filter.driver,
    };
    return this.http.post<IDriveBooksDTO>(DRIVEBOOK_ENDPOINTS.fetchDriveBooks(
      this.appConfigService.configuration.API_URL, vehicleId), body);
  }

  public fetchDriveBookPaths(driveBookID: number): Observable<IDriveBookPathDTO> {
    return this.http.get<IDriveBookPathDTO>(DRIVEBOOK_ENDPOINTS.fetchDriveBookPaths(
      this.appConfigService.configuration.API_URL, driveBookID));
  }

  public fetchDriveBook(driveBookID: number): Observable<IDriveBookDTO> {
    return this.http.get<IDriveBookDetailDTO>(DRIVEBOOK_ENDPOINTS.fetchDriveBook(
      this.appConfigService.configuration.API_URL, driveBookID)).pipe(
        map((response) => response.driveBook)
      );
  }

  public updateDriveBook(driveBookID: number, driveBook: IPutDriveBookDTO): Observable<IDriveBookDTO> {
    return this.http.put<IDriveBooksDTO>(DRIVEBOOK_ENDPOINTS.updateDriveBook(
      this.appConfigService.configuration.API_URL, driveBookID), driveBook).pipe(
        switchMap(() => this.fetchDriveBook(driveBookID))
    );
  }

  public fetchAllDrivers(): Observable<ICarDriverDTO[]> {
    return this.http.get<ICarDriversDTO>(DRIVEBOOK_ENDPOINTS.fetchAllDrivers(this.appConfigService.configuration.API_URL)).pipe(
      map(x => x.drivers.drivers)
    );
  }
}

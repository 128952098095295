import { Injectable } from '@angular/core';
import { StoreConfig, EntityStore } from '@datorama/akita';
import { ICarGroupDTO, ICarConciseDTO, ICarColorDTO, ICarFuelDTO, ICarBodyDTO, ICarDriversDTO, ICarLastTachoValueDTO } from './vehicle.model';

export interface IVehicleState {
  carGroups: ICarGroupDTO[];
  loading: boolean;
  vehicleInfo?: ICarConciseDTO;
  vehicleColors?: ICarColorDTO[];
  vehicleFuels?: ICarFuelDTO[];
  vehicleBodies?: ICarBodyDTO[];
  vehicleDrivers?: ICarDriversDTO;
  vehicleLastTacho?: ICarLastTachoValueDTO;
}

export function createInitialState(): IVehicleState {
  return {
    carGroups: null,
    loading: false
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'vehicles' })
export class VehicleStore extends EntityStore<IVehicleState> {

  public constructor() {
    super(createInitialState());
  }
}

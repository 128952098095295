import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { CommonVehicleComponent } from '../common/common-vehicle.component';
import { ControlPosition, MapTypeControlOptions } from '@agm/core';
import { IMapPoint } from 'src/app/store/map/map.model';
import { IDriveBookDTO, IDriveBookPathDTO, DriveType, IPutDriveBookDTO } from 'src/app/store/drive-book/drive-book.model';
import { DriveBookQuery } from 'src/app/store/drive-book/drive-book.query';
import { DriveBookService } from 'src/app/store/drive-book/drive-book.service';
import { ICarDriverDTO } from 'src/app/store/vehicle/vehicle.model';
import { VehicleStore } from 'src/app/store/vehicle/vehicle.store';

export enum Color {
  Red = '#EF5350',
  Green = '#81C784',
  Orange = '#FF9800'
}

@Component({
  selector: 'app-drivebook-detail',
  templateUrl: './drivebook-detail.component.html',
  styleUrls: ['./drivebook-detail.component.scss']
})
export class DrivebookDetailComponent extends CommonVehicleComponent implements OnInit, OnDestroy {

  zoom = 13;
  drivebookDetail: IDriveBookDTO;
  drivebookPath: IDriveBookPathDTO;
  drivebookQuery: DriveBookQuery;
  drivebookService: DriveBookService;
  centerPoint: IMapPoint;
  driveTypes = DriveType;
  drivers: ICarDriverDTO[];
  detailExpanded = false;
  editMode = false;
  noPathData = false;
  public readonly mapTypeControlOptions: MapTypeControlOptions = {
    position: ControlPosition.LEFT_BOTTOM,
  };

  constructor(injector: Injector, private vehicleStore: VehicleStore) {
    super(injector);
    this.drivebookQuery = injector.get(DriveBookQuery);
    this.drivebookService = injector.get(DriveBookService);
    this.setTitle('page-title.drive-book-detail');
    this.subscriptions.add(this.vehicleQuery.allCars$.subscribe(() => {
      this.setActiveVehicle(this.onActiveVehicle, this.onErrorActiveVehicle);
    }));
    this.subscriptions.add(this.drivebookQuery.drivebookDetail$.subscribe((drivebookDetail) => {
      this.drivebookDetail = drivebookDetail;
    }));
    this.subscriptions.add(this.drivebookQuery.drivebookPath$.subscribe((drivebookPath) => {
      this.drivebookPath = drivebookPath;
      this.handleDrivebookPath();
    }));
    this.subscriptions.add(this.vehicleQuery.vehicleDrivers$.subscribe((drivers) =>
      this.drivers = drivers?.drivers.drivers
      ));

    this.setIsLoading(true);
  }

  ngOnInit(): void {
    this.loadInitialData();
  }

  public getDriversName(driverId: number): string {
    if (this.drivers !== undefined) {
      if (driverId !== -1) {
        const driver = this.drivers.find(x => x.nPersonID === driverId);
        return driver?.strSurname;
      } else {
        return '';
      }
    }
  }

  loadInitialData(): void {
    this.drivebookService.fetchDrivebookDetail(this.drivebookId, this.createDrivebookEditForm, () => { });
    this.drivebookService.fetchDrivebookPath(this.drivebookId, () => {
      this.setIsLoading(false);
    }, () => {
      this.setIsLoading(false);
      this.noPathData = true;
      this.detailExpanded = true;
    });
    this.createDrivebookEditForm();
  }

  onActiveVehicle = (): void => {
    this.setTitle('page-title.drive-book-detail', this.activeVehicle);
    this.vehicleService.fetchDriversByVehicle(this.vehicleId);
  }

  onErrorActiveVehicle = (): void => {
  }

  createDrivebookEditForm = (): void => {
    if (!!this.drivebookDetail) {
      this.createForm({
        driver: [this.drivebookDetail?.driverId],
        driveType: [this.drivebookDetail?.driveType],
        purpose: [this.drivebookDetail?.purpose],
        fuelRefillLitres: [this.drivebookDetail?.fuelRefillLitres],
        fuelRefillPrice: [this.drivebookDetail?.fuelRefillPrice],
        note: [],
      });
    }
  }

  handleDrivebookPath(): void {
    const points = this.drivebookPath?.driveBookPath?.path;
    if (!points) {
      return;
    }
    const radius = this.utilityService.calculateRadius(points);
    this.centerPoint = this.utilityService.calculateCenterPoint(points);
    this.zoom = this.utilityService.calculateZoomLevel(radius);
  }

  ngOnDestroy(): void {
    this.drivebookService.clearVehicleDetail();
    this.drivebookService.clearVehiclePath();
  }

  toogleDetailView(): void {
    this.detailExpanded = !this.detailExpanded || this.noPathData;
  }
  swipeDown(): void {
    if (this.noPathData) {
      return;
    }
    this.detailExpanded = false;
  }

  swipeUp(): void {
    if (this.noPathData) {
      return;
    }
    this.detailExpanded = true;
  }

  toogleEditView(): void {
    this.editMode = !this.editMode;
  }

  cancelEdit(): void {
    this.createDrivebookEditForm();
    this.toogleEditView();
  }

  onEdit(): void {
    const note = this.getTranslatedKey('drive-book.edit-form.stop.tachometer-message',
    { tachometer: this.form.value.note, distance: this.drivebookDetail.traceDistance});
    const request: IPutDriveBookDTO = {
      averageSpeed: this.drivebookDetail.speeding,
      bill: this.drivebookDetail.receipt,
      distance: this.drivebookDetail.traceDistance,
      drivePurpose: this.form.value.purpose,
      drivebookId: this.drivebookDetail.driveBookId,
      driverId: this.form.value.driver,
      fuelAmount: this.form.value.fuelRefillLitres,
      fuelPrice: this.form.value.fuelRefillPrice,
      maxSpeed: this.drivebookDetail.maxSpeed,
      note: !!this.form.value.note ? note : this.drivebookDetail.note,
      startDate: this.drivebookDetail.dateTimeStart,
      startPlace: this.drivebookDetail.startDescription,
      startTacho: this.drivebookDetail.tachoStart,
      stopDate: this.drivebookDetail.dateTimeStop,
      stopPlace: this.drivebookDetail.stopDescription,
      stopTacho: this.drivebookDetail.tachoStop,
      tachoChange: this.drivebookDetail.newTacho < 0 ? null : this.drivebookDetail.newTacho,
      type: this.form.value.driveType,
    };
    this.drivebookService.updateDrivebook(this.drivebookId, request, () => {
      this.loadInitialData();
      this.toogleEditView();
    }, () => {
      const errorLabel = this.getTranslatedKey('drive-book.edit-form.error');
      this.showError(errorLabel);
    });
  }
}

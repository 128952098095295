<div class="map-container" *ngIf="!!vehicleInfo && !!activeVehicle">
  <ui-divider></ui-divider>
  <ng-container *ngFor="let item of vehicleInfoFormatted">
    <div class="row">
      <b>{{ item?.label | translate }}</b>
      <p class="small light"
        *ngIf="!item.translateValue"> {{ item?.value || '-' }}</p>
      <p class="small light"
        *ngIf="item.translateValue">{{ (item?.value | translate) || '-'}}</p>
    </div>
  </ng-container>
</div>

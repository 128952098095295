import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface IWorkspaceState {
    id: number;
    name: string;
    contactEmail: string;
    contactPhone: string;
    contactCompanyName: string;
    contactCompanyStreet: string;
    contactCompanyCity: string;
    contactCompanyUrl: string;
    logoPath: string;
    smallLogoPath: string;
    faviconPath: string;
    loginBackgroundpath: string;
}

export function createInitialState(): IWorkspaceState {
  return {
    id: null,
    name: null,
    contactEmail: null,
    contactPhone: null,
    contactCompanyName: null,
    contactCompanyStreet: null,
    contactCompanyCity: null,
    contactCompanyUrl: null,
    logoPath: null,
    smallLogoPath: null,
    faviconPath: null,
    loginBackgroundpath: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'workspace' })
export class WorkspaceStore extends Store<IWorkspaceState> {
  public constructor() {
    super(createInitialState());
  }
}

import { Injectable } from '@angular/core';
import { DriveBookStore, createInitialState } from './drive-book.store';
import { DriveType, DriveTypeString, IDriveBookFilter, IPutDriveBookDTO } from './drive-book.model';
import { DriveBookHttpService } from './drive-book-http.service';

@Injectable({ providedIn: 'root' })
export class DriveBookService {

  public constructor(
    private driveBookStore: DriveBookStore,
    private driveBookHttpService: DriveBookHttpService,
  ) { }

  private convertDriveType(type: string): number {
    if (type === DriveTypeString.Business) {
      return DriveType.Business;
    } else if (type === DriveTypeString.Private) {
      return DriveType.Private;
    } else {
      return DriveType.All;
    }
  }

  public updateFilter(filter: IDriveBookFilter): void {
    this.driveBookStore.update({ filter });
    this.removeAllDrivebooks();
  }

  public createInitialFilter(): void {
    const intervalFrom = new Date();
    intervalFrom.setDate(new Date().getDate() - 7);
    const filter: IDriveBookFilter = {
      driver: 0,
      intervalFrom: intervalFrom.toISOString(),
      intervalTo: new Date().toISOString(),
      type: DriveType.All,
    };
    this.driveBookStore.update({ filter });
  }

  public removeAllDrivebooks() {
    this.driveBookStore.update({
      drivebooks: null,
    });
  }

  public fetchDrivebooks(filter: IDriveBookFilter, vehicleId: number, onSucceeded: () => void, onError: () => void): void {
    this.driveBookHttpService.fetchDriveBooksAndTotals(filter, vehicleId).subscribe((response) => {
      this.driveBookStore.update({ totals: response, drivebooks: response.driveBooks });
      onSucceeded();
    }, () => {
      onError();
    });
  }

  public fetchDrivebookDetail(drivebookId: number, onSucceeded: () => void, onError: () => void): void {
    this.driveBookHttpService.fetchDriveBook(drivebookId).subscribe((drivebookDetail) => {
      this.driveBookStore.update({ drivebookDetail });
      onSucceeded();
    }, () => {
      onError();
    });
  }

  public fetchDrivebookPath(drivebookId: number, onSucceeded: () => void, onError: () => void): void {
    this.driveBookHttpService.fetchDriveBookPaths(drivebookId).subscribe((drivebookPath) => {
      this.driveBookStore.update({ drivebookPath });
      onSucceeded();
    }, () => {
      onError();
    });
  }

  public updateDrivebook(drivebookId: number, drivebook: IPutDriveBookDTO, onSucceeded: () => void, onError: () => void): void {
    this.driveBookHttpService.updateDriveBook(drivebookId, drivebook).subscribe(() => {
      onSucceeded();
    }, () => {
      onError();
    });
  }

  public fetchDrivers(onSucceeded: () => void, onError: () => void): void {
    this.driveBookHttpService.fetchAllDrivers().subscribe((drivers) => {
      this.driveBookStore.update({ drivers });
      onSucceeded();
    }, () => {
      onError();
    });
  }

  public clearStore(): void {
    this.driveBookStore.update(createInitialState());
  }

  public clearVehicleDetail(): void {
    this.driveBookStore.update({ drivebookDetail: null });
  }
  public clearVehiclePath(): void {
    this.driveBookStore.update({ drivebookPath: null });
  }
}

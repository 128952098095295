<div class="wrapper"
  *ngIf="!!drivebooks">
  <div class="filter-container"
    (click)="onFilter()">
    <div class="filter-info-block">
      {{ filter?.intervalFrom | date: 'd.L.yyyy' }} -
      {{ filter?.intervalTo | date: 'd.L.yyyy' }}
      <ng-container [ngSwitch]="filter?.type">
        <span class="small light"
          *ngSwitchCase="driveTypes.Business"> - {{ 'drive-book.totals.business' | translate }}</span>
        <span class="small light"
          *ngSwitchCase="driveTypes.Private"> - {{ 'drive-book.totals.private' | translate }}</span>
      </ng-container>
      {{  !!filter?.driver ? ' - ' + filter?.driver  : '' }}
    </div>
    <div>
      <mat-icon class="filter-icon">filter_list</mat-icon>
    </div>
  </div>
  <ng-container *ngFor="let drivebookGroup of drivebooks">
    <mat-expansion-panel [expanded]="(drivebookGroup?.day === selectedDay)" #panel>
      <mat-expansion-panel-header class="drivebook-group-row-wrapper"
        [collapsedHeight]="'40px'"
        [expandedHeight]="'40px'">
        <div class="row between v-center header-content">
          <div class="main-header">{{ drivebookGroup?.day | stringToTimestamp | timestampToString: "mediumDate"}}</div>
          <ui-divider [vertical]="true" [compact]="true"></ui-divider>
          <div class="row light small">
            <div class="inline-container">{{ drivebookGroup?.driveBooks?.driveBooks?.length }}&nbsp;
              <span [ngPlural]="drivebookGroup?.driveBooks?.driveBooks?.length">
                <ng-template ngPluralCase="=1">{{ "drive-book.totals.drive" | translate }}</ng-template>
                <ng-template ngPluralCase="=2">{{ "drive-book.totals.drives2" | translate }}</ng-template>
                <ng-template ngPluralCase="=3">{{ "drive-book.totals.drives2" | translate }}</ng-template>
                <ng-template ngPluralCase="=4">{{ "drive-book.totals.drives2" | translate }}</ng-template>
                <ng-template ngPluralCase="other">{{ "drive-book.totals.drives" | translate }}</ng-template>
              </span>
            </div>
            <ui-divider [vertical]="true" [compact]="true"></ui-divider>
            <div class="inline-container">{{ drivebookGroup?.driveBooks?.totalDistance | number: '1.0-0'}} km</div>
            <ui-divider [vertical]="true" [compact]="true"></ui-divider>
            <div>{{ drivebookGroup?.driveBooks?.totalDuration | durationTime }}</div>
            <ui-divider [vertical]="true"></ui-divider>
          </div>
        </div>
      </mat-expansion-panel-header>
      <ng-container *ngIf="panel.expanded">
      <ng-container *ngFor="let drivebook of drivebookGroup?.driveBooks?.driveBooks">
        <div class="drivebook-row" (click)="onSelectDrivebook(drivebook, drivebookGroup)">
            <div class="row v-center">
              <ng-container *ngIf="drivebook?.driveType === 1">
                <img src="./../../../../assets/icons/private.svg" height="30px" matTooltipPosition="right"
                  [matTooltip]="'drive-book.type.private' | translate">
              </ng-container>
              <ng-container *ngIf="drivebook?.driveType === 0">
                <img src="./../../../../assets/icons/business.svg" height="30px" matTooltipPosition="right"
                  [matTooltip]="'drive-book.type.business' | translate">
              </ng-container>
                <div class="column info">
                  <p class="small light">{{ drivebook?.dateTimeStart | stringToTimestamp | date:"H:mm"}}</p>
                  <b class="small light" *ngIf="isDisplayed(drivebook)">{{ drivebook?.startDescription }}</b>
                  <ui-divider [compact]="true"></ui-divider>
                  <p class="small light">{{ drivebook?.dateTimeStop | stringToTimestamp | date:"H:mm"}}</p>
                  <b class="small light" *ngIf="isDisplayed(drivebook)">{{ drivebook?.stopDescription }}</b>
              </div>
            </div>
            <div class="row right">
              <div class="row v-center">
                <mat-icon *ngIf="!!drivebook?.purpose" class="transparent purpose" matTooltipPosition="right" [matTooltip]="drivebook?.purpose">sticky_note_2</mat-icon>
                <ui-divider [vertical]="true" [compact]="true"></ui-divider>
              </div>
              <div class="column distance">
                <b class="light">{{ drivebook?.traceDistance | number: "1.0-1" }} km</b>
                <p class="small light">{{ drivebook?.tachoStop | number: "1.0-0" }} km</p>
                <p class="light">{{ getDriversName(drivebook?.driverId) }}</p>
              </div>
            </div>
        </div>
      </ng-container>
    </ng-container>
    </mat-expansion-panel>
  </ng-container>
  <div class="column center" *ngIf="drivebooks?.length === 0">
    <ui-divider></ui-divider>
    <i style="text-align: center;">{{ 'drive-book.drives.no-drive' | translate }}</i>
  </div>
</div>

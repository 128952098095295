export const UrlRoutes = {
  urls: {
    loginUrl: '/login',
    forgotPasswordUrl: '/login/forgot-password',
    forgotPasswordSetUrl: '/login/forgot-password-set',
    dashboardUrl: '/dashboard',
    vehicleDetailUrl: '/dashboard/{0}',
    vehicleInfoUrl: '/dashboard/{0}/info',
    vehicleMapUrl: '/dashboard/{0}/map',
    vehiclesMapUrl: '/dashboard/map',
    vehicleDrivebookUrl: '/dashboard/{0}/drivebook',
    vehicleDrivebookFilterUrl: '/dashboard/{0}/drivebook/filter',
    drivebookDetailUrl: '/dashboard/{0}/drivebook/detail/{1}',
  },
  mapUrls: {
    google: 'https://www.google.com/maps/dir/?api=1&destination={0},{1}',
    apple: 'https://maps.apple.com/?daddr={0},{1}'
  }
};

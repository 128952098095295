import { Pipe, PipeTransform } from '@angular/core';
import { VehicleStateEnum } from 'src/app/store/vehicle/vehicle.model';

@Pipe({
  name: 'vehicleMapMarker',
  pure: true
})
export class VehicleMapMarkerPipe implements PipeTransform {
  public transform(value: VehicleStateEnum): string {
    if (!value) {
      return;
    }
    const path = 'assets/images/';
    switch (value) {
      case VehicleStateEnum.KEY_ON_SPEED_ON_DATA_ON:
        return `${ path }green_play.png`;
      case VehicleStateEnum.KEY_ON_SPEED_ON_DATA_OFF:
        return `${ path }red_play.png`;
      case VehicleStateEnum.KEY_OFF_SPEED_ON_DATA_ON:
        return `${ path }green_square.png`;
      case VehicleStateEnum.KEY_OFF_SPEED_ON_DATA_OFF:
        return `${ path }blue_square.png`;
      case VehicleStateEnum.KEY_ON_SPEED_OFF_DATA_ON:
        return `${ path }green_pause.png`;
      case VehicleStateEnum.KEY_ON_SPEED_OFF_DATA_OFF:
        return `${ path }red_pause.png`;
      default:
        return `${ path }red_pause.png`;
    }
  }
}

import { OnInit, OnDestroy, Injector, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { AppService } from 'src/app/store/app/app.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NotifyService } from 'src/app/services/notify.service';
import { LanguageService } from 'src/app/services/language.service';
import { AppQuery } from 'src/app/store/app/app.query';
import { ICarDTO } from 'src/app/store/vehicle/vehicle.model';
import { AuthQuery } from 'src/app/store/auth/auth.query';
import { IUserInfo, IUserInfoDTO } from 'src/app/store/auth/auth.model';

export class CommonComponent implements OnInit, OnDestroy {

  public subscriptions: Subscription;
  public activeRoute: ActivatedRoute;
  public form: FormGroup;
  public selectedLanguage: string;
  public isLoading: boolean;
  public isLoggedIn: boolean;
  public title: string;
  public subtitle: string;
  public utilityService: UtilitiesService;
  public updateTriger: number;
  public userInfo: IUserInfo;
  public profile: IUserInfoDTO;
  //
  private router: Router;
  private appService: AppService;
  private translateService: TranslateService;
  private languageService: LanguageService;
  private appQuery: AppQuery;
  private authQuery: AuthQuery;
  private fb: FormBuilder;
  private notificationService: NotifyService;
  private changeDetector: ChangeDetectorRef;

  public constructor(private injector: Injector) {
    this.subscriptions = new Subscription();
    this.router = this.injector.get(Router);
    this.utilityService = this.injector.get(UtilitiesService);
    this.appService = this.injector.get(AppService);
    this.translateService = this.injector.get(TranslateService);
    this.languageService = this.injector.get(LanguageService);
    this.activeRoute = this.injector.get(ActivatedRoute);
    this.fb = injector.get(FormBuilder);
    this.notificationService = injector.get(NotifyService);
    this.appQuery = injector.get(AppQuery);
    this.authQuery = injector.get(AuthQuery);
    this.changeDetector = injector.get(ChangeDetectorRef);
    this.selectedLanguage = this.languageService.getLanguage();
    this.subscriptions.add(
      this.translateService.onLangChange.subscribe(() => {
        this.selectedLanguage = this.languageService.getLanguage();
      }));
    this.subscriptions.add(this.appQuery.title$.subscribe((title) => this.title = title));
    this.subscriptions.add(this.appQuery.subtitle$.subscribe((subtitle) => this.subtitle = subtitle));
    this.subscriptions.add(this.appQuery.isLoading$.subscribe((isLoading) => this.isLoading = isLoading));
    this.subscriptions.add(this.authQuery.isLoggedIn$.subscribe((isLoggedIn) => this.isLoggedIn = isLoggedIn));
    this.subscriptions.add(this.authQuery.userInfo$.subscribe((userInfo) => this.userInfo = userInfo));
    this.subscriptions.add(this.authQuery.profile$.subscribe((profile) => this.profile = profile));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setTitle(title: string, activeVehicle?: ICarDTO): void {
    this.translateService.get(title).subscribe((result) => {
      this.appService.setTitle(result, !!activeVehicle ? `${ activeVehicle.spz } - ${ activeVehicle.toolTipText }` : null);
    });
  }

  setIsLoading(loading: boolean): void {
    this.appService.setIsLoading(loading);
  }

  navigate(url: string, p1?: any, p2?: any) {
    this.router.navigateByUrl(this.utilityService.format(url, p1, p2));
  }

  navigateWithQueryParams(url: string, queryParams: any, p1?: any, p2?: any) {
    this.router.navigate([this.utilityService.format(url, p1, p2)], { queryParams });
  }

  createForm(formControls: any): void {
    this.form = this.fb.group(formControls);
  }

  addQueryParams(queryParams: any): void {
    this.router.navigate(['.'], { relativeTo: this.activeRoute, queryParams });
  }
  getQueryParams(): any {
    const queryObject = this.activeRoute.snapshot.queryParams;
    return Object.keys(queryObject).length > 0 ? queryObject : null;
  }

  getSelectedVehicleIds(): number[] {
    const queryObject = this.activeRoute.snapshot.queryParams.ids;
    if (typeof queryObject === 'object') {
      return !!queryObject ? queryObject?.map(o => Number(o)) : [];
    } else {
      return [Number(queryObject)];
    }
  }

  getParam(name: string): any {
    return this.activeRoute.snapshot.paramMap.get(name);
  }

  showError = (text: string): void => {
    this.notificationService.showError(text);
  }
  showInfo = (text: string): void => {
    this.notificationService.showInfo(text);
  }

  getTranslatedKey(key: string , params?: any): string {
    if (!!params) {
      return this.translateService.instant(key, params);
    } else {
      return this.translateService.instant(key);
    }
  }
  changeLanguage(language: string): void {
    this.languageService.changeLanguage(language);
  }
  detectChanges(): void {
    this.changeDetector.detectChanges();
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { take } from 'rxjs/operators';
import { CarsPositionStore, ICarsPositionsState, createInitialState } from './cars-position.store';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { VehicleHttpService } from 'src/app/store/vehicle/vehicle-http.service'
import { VehicleService } from 'src/app/store/vehicle/vehicle.service'
import { ICarsCurrentPosition } from '../vehicle/vehicle.model';

import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class CarsPositionService {

  public constructor(
    public carsPositionStore: CarsPositionStore,
    private utilities: UtilitiesService,
    private vehicleHttpService: VehicleHttpService,
    private vehicleService: VehicleService,
  ) { }



  public clearStore(): void {
    this.carsPositionStore.update(createInitialState());
  }

  public getNewPositionsFromServer(lastQueryTime: number): Observable<number> {

    return new Observable<number>(observer => {
  
        this.vehicleHttpService.fetchCarsPositionsByUserID(lastQueryTime).subscribe(newCarPositions=>{

          if(newCarPositions){
            this.vehicleService.setCurrentPositions(newCarPositions);
            // Emit the result as a number value
            observer.next(newCarPositions.lastTickTime);
          }
          else{
            observer.next(lastQueryTime);
          }
      
          // Complete the observable
          observer.complete();
   
        },
        ()=>{
          observer.next(lastQueryTime);
          observer.complete();
        }
        );
    });
  } 

}

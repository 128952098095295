import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IUserInfo, IUserInfoDTO } from './auth.model';

export interface IAuthState {
  userInfo?: IUserInfo;
  profile?: IUserInfoDTO;
}

export function createInitialState(): IAuthState {
  return {};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<IAuthState> {

  public constructor() {
    super(createInitialState());
  }

}

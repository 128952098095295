import { Pipe, PipeTransform } from '@angular/core';
import { UtilitiesService } from 'src/app/services/utilities.service';
import * as moment from 'moment';
import { VehicleStateEnum, ICarPositionExtra } from 'src/app/store/vehicle/vehicle.model';

@Pipe({
  name: 'vehicleState',
  pure: true
})
export class VehicleStatePipe implements PipeTransform {
  intervalData = 5;
  speedThreshold = 1;

  public constructor(
    private utilityService: UtilitiesService
  ) { }

  public transform(vehiclePosition: ICarPositionExtra, trigger?: any): VehicleStateEnum {
    if (!vehiclePosition) {
      return VehicleStateEnum.KEY_OFF_SPEED_ON_DATA_OFF;
    }
    const dataOn = this.utilityService
      .getTimeDiffInMinutes(moment().local(), moment.utc(vehiclePosition?.lastKnownDate).local()) < this.intervalData;
    const keyOn = vehiclePosition?.isKeyOn;
    const speedOn = vehiclePosition?.speed > this.speedThreshold;
    if (keyOn && speedOn && dataOn) {
      return VehicleStateEnum.KEY_ON_SPEED_ON_DATA_ON;
    } else if (keyOn && speedOn && !dataOn) {
      return VehicleStateEnum.KEY_ON_SPEED_ON_DATA_OFF;
    } else if (!keyOn && dataOn) {
      return VehicleStateEnum.KEY_OFF_SPEED_ON_DATA_ON;
    } else if (!keyOn && !dataOn) {
      return VehicleStateEnum.KEY_OFF_SPEED_ON_DATA_OFF;
    } else if (keyOn && !speedOn && dataOn) {
      return VehicleStateEnum.KEY_ON_SPEED_OFF_DATA_ON;
    } else if (keyOn && !speedOn && !dataOn) {
      return VehicleStateEnum.KEY_ON_SPEED_OFF_DATA_OFF;
    }
  }
}

import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { MapStore, IMapState } from './map.store';
import { debounceTime } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MapQuery extends Query<IMapState> {

  public readonly isInitialized$ = this.select((state) => state.isInitialized);

  public readonly zoom$ = this.select((state) => state.zoom);

  public readonly centerPoint$ = this.select((state) => state.centerPoint);

  public readonly points$ = this.select((state) => state.points);

  public readonly speedPoint$ = this.select((state) => state.speedPoint).pipe(debounceTime(10));

  public readonly lines$ = this.select((state) => state.lines);

  public readonly pointsCount$ = this.select((state) => state.points.length);

  public readonly linesCount$ = this.select((state) => state.lines.length);

  public readonly focusedVehicle$ = this.select((state) => state.focusedVehicleId).pipe(debounceTime(20));

  public readonly selectedVehicleIds$ = this.select((state) => state.selectedVehicleIds);

  public constructor(protected store: MapStore) {
    super(store);
  }

}

import { Component, OnInit, Injector } from '@angular/core';
import { CommonVehicleComponent } from '../common/common-vehicle.component';
import { IDriveBookDayDTO, IDriveBookFilter, DriveType, IDriveBookDTO } from 'src/app/store/drive-book/drive-book.model';
import { DriveBookQuery } from 'src/app/store/drive-book/drive-book.query';
import { DriveBookService } from 'src/app/store/drive-book/drive-book.service';
import { UrlRoutes } from 'src/app/app-routes';
import { DriveBookStore } from 'src/app/store/drive-book/drive-book.store';
import { ICarDriverDTO } from 'src/app/store/vehicle/vehicle.model';

@Component({
  selector: 'app-vehicle-drivebook',
  templateUrl: './vehicle-drivebook.component.html',
  styleUrls: ['./vehicle-drivebook.component.scss']
})
export class VehicleDrivebookComponent extends CommonVehicleComponent implements OnInit {
  driveTypes = DriveType;
  drivebooks: IDriveBookDayDTO[];
  drivebookQuery: DriveBookQuery;
  drivebookService: DriveBookService;
  drivebookStore: DriveBookStore;
  filter: IDriveBookFilter;
  gdpr: boolean;
  selectedDay: string;
  drivers: ICarDriverDTO[];

  constructor(injector: Injector) {
    super(injector);
    this.drivebookQuery = injector.get(DriveBookQuery);
    this.drivebookService = injector.get(DriveBookService);
    this.drivebookStore =  injector.get(DriveBookStore);
    this.subscriptions.add(this.vehicleQuery.allCars$.subscribe(() =>
      this.setActiveVehicle(this.onActiveVehicle, this.onErrorActiveVehicle)
    ));
    this.subscriptions.add(this.drivebookQuery.drivebooks$.subscribe((drivebooks) => this.drivebooks = drivebooks));
    this.subscriptions.add(this.drivebookQuery.filter$.subscribe((filter) => this.filter = filter));
    this.subscriptions.add(this.drivebookQuery.drivers$.subscribe((drivers) => this.drivers = drivers));
    this.drivebookQuery.selectedDrivebookDay$.subscribe(day => this.selectedDay = day?.day);
    const queryFilter = this.getQueryParams();
    if (!this.filter && !!queryFilter) {
      this.changeFilterQuery(queryFilter);
    }
    if (!this.filter) {
      this.drivebookService.createInitialFilter();
    }
    this.gdpr = this.profile?.gdpr;
  }

  ngOnInit(): void {
    this.setIsLoading(true);
    this.drivebookService.fetchDrivebooks(this.filter, this.vehicleId, this.onDrivebooksSucceed, this.onDrivebooksError);
  }

  public isDisplayed(drivebook: IDriveBookDTO) {
    return drivebook?.driveType === 0 || drivebook?.driveType === -1 || (drivebook?.driveType === 1 && this.gdpr);
    }

  onActiveVehicle = (): void => {
    this.setTitle('page-title.drive-book', this.activeVehicle);
  }

  onErrorActiveVehicle = (): void => {
  }

  onDrivebooksSucceed = (): void => {
    this.drivebookService.fetchDrivers(this.onDriversSucceed, this.onDrivebooksError);
  }

  onDriversSucceed = (): void => {
    this.setIsLoading(false);
  }

  onDrivebooksError = (): void => {
    this.setIsLoading(false);
  }

  onFilter(): void {
    this.navigate(UrlRoutes.urls.vehicleDrivebookFilterUrl, this.vehicleId);
  }

  changeFilterQuery(queryFilter: any): void {
    const parsedFilter: IDriveBookFilter = {
      intervalFrom: queryFilter.intervalFrom,
      intervalTo: queryFilter.intervalTo,
      type: parseInt(queryFilter.type, 10),
      driver: parseInt(queryFilter.driver, 10),
    };
    this.drivebookService.updateFilter(parsedFilter);
  }

  public getDriversName(driverId: number): string {
    if (!!this.drivers) {
      if (driverId !== -1) {
        const driver = this.drivers.find(x => x.nPersonID === driverId);
        return driver?.strSurname;
      } else {
        return '';
      }
    }
  }

  onSelectDrivebook(drivebook: IDriveBookDTO, selectedDrivebookDay: IDriveBookDayDTO): void {
    this.drivebookStore.update({ selectedDrivebookDay });
    if (!this.gdpr && drivebook.driveType === 1) {
      return;
    }

    this.navigate(UrlRoutes.urls.drivebookDetailUrl, this.vehicleId, drivebook.driveBookId);
  }
}

import { Component, Injector } from '@angular/core';
import { CommonVehicleComponent } from '../common/common-vehicle.component';
import { ControlPosition, MapTypeControlOptions } from '@agm/core';
import { interval } from 'rxjs';
import { IMapPolylinePoint, IMapPolyline, IMapPoint } from 'src/app/store/map/map.model';
import * as moment from 'moment';
import { VehicleStateEnum, ICarDTO } from 'src/app/store/vehicle/vehicle.model';
import { MapQuery } from 'src/app/store/map/map.query';

@Component({
  selector: 'app-vehicle-map',
  templateUrl: './vehicle-map.component.html',
  styleUrls: ['./vehicle-map.component.scss']
})
export class VehicleMapComponent extends CommonVehicleComponent {

  zoom = 13;
  singleVehicle: ICarDTO;
  center: IMapPoint;
  liveLines: IMapPolyline[] = [];
  vehicleStates = VehicleStateEnum;
  mapQuery: MapQuery;
  selectedVehicleIds: number[];
  infoWindowOpened: number[] = [];
  selectedVehicles: ICarDTO[];
  public readonly mapTypeControlOptions: MapTypeControlOptions = {
    position: ControlPosition.LEFT_BOTTOM,
    style: 0
  };

  constructor(injector: Injector) {
    super(injector);
    this.mapQuery = injector.get(MapQuery);
    this.setTitle('vehicles.map');
    this.subscriptions.add(interval(5000).subscribe(() => {
      this.updateTriger = new Date().getMilliseconds();
      this.processLiveLines();
    }));
    this.subscriptions.add(this.vehicleQuery.allCars$.subscribe((allVehicles) => {
      this.onActiveVehicles();
    }));
    this.subscriptions.add(this.vehicleQuery.carGroups$.subscribe(() => {
      this.processLiveLines();
    }));
    this.subscriptions.add(this.mapQuery.lines$.subscribe(() => {
      this.processLiveLines();
    }));

    this.subscriptions.add(this.mapQuery.selectedVehicleIds$.subscribe((selectedVehicleIds) => {
      this.selectedVehicleIds = !!selectedVehicleIds && selectedVehicleIds?.length > 0 ? selectedVehicleIds : this.getSelectedVehicleIds();
      this.onActiveVehicles();
    }));
  }

  onActiveVehicles = (): void => {
    if (!!this.vehicles && this.selectedVehicleIds) {
      this.selectedVehicles = this.vehicles.filter(vehicle => this.selectedVehicleIds.includes(vehicle.carID));
      this.intialZoomMap();
      this.infoWindowOpened = this.selectedVehicleIds;
      if (this.selectedVehicles?.length === 1) {
        this.singleVehicle = this.selectedVehicles[0];
        this.setTitle('vehicles.map', this.singleVehicle);
        this.zoom = 15;
      } else {
        this.setTitle('vehicles.map');
      }
    }
  }

  intialZoomMap(): void {
    const radius = this.utilityService.calculateRadius(this.selectedVehicles.map(vehicle => vehicle.position));
    this.center = this.utilityService.calculateCenterPoint(this.selectedVehicles.map(vehicle => vehicle.position));
    this.zoom = Math.min(this.utilityService.calculateZoomLevel(radius), 10);
  }

  public trackByFunctionPolyline(index, item: IMapPolyline) {
    return !!item ? item.drivebookId : index;
  }

  private processLiveLines() {
    this.selectedVehicles?.forEach((vehicle) => {
      const line = this.liveLines?.find(x => x?.paths?.find(y => y.carId === vehicle?.carID));
      const lastPathTimestamp = moment.utc(line?.paths[0]?.timestamp).local();
      const lastPathDiff = this.utilityService.getTimeDiffInMinutes(moment().local(), lastPathTimestamp);
      if (line && line?.paths?.length > 20 || lastPathDiff > 2) {
        line.paths.splice(0, 1);
      }

      if (!line) {
        if (!!vehicle?.position) {
          const pointMarker: IMapPolylinePoint = {
            carId: vehicle?.carID,
            latitude: vehicle?.position?.latitude,
            longitude: vehicle?.position?.longitude,
            speed: 0,
            timestamp: vehicle?.lastKnownDate,
          };

          const paths: IMapPolyline = { drivebookId: vehicle?.carID, paths: [], isDriveBook: false };
          paths.paths.push(pointMarker);
          this.liveLines.push(paths);
        }
      } else {
        const pointMarker: IMapPolylinePoint = {
          carId: vehicle?.carID,
          latitude: vehicle?.position?.latitude,
          longitude: vehicle?.position?.longitude,
          speed: vehicle?.speed,
          timestamp: vehicle?.lastKnownDate,
        };
        line.paths.push(pointMarker);
      }
    });
    this.liveLines = this.liveLines.filter(l => this.selectedVehicleIds.includes(l.drivebookId));
  }

  public infoWindowClosed(id: number): void {
    this.infoWindowOpened = this.infoWindowOpened.filter(i => i !== id);
  }

  public clickedMarker(id: number): void {
    if (this.infoWindowOpened.indexOf(id) === -1) {
      this.infoWindowOpened.push(id);
    } else {
      this.infoWindowOpened = this.infoWindowOpened.filter(x => x !== id);
    }
  }
}

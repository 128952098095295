import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { HomeComponent } from './components/home/home.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { LoginComponent } from './components/login/login.component';
import { CustomMaterialModule } from './material.module';
import { UiComponentsModule } from './ui-components.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SiteLayoutComponent } from './layouts/site-layout/site-layout.component';
import { VehicleDetailComponent } from './components/vehicle-detail/vehicle-detail.component';
import { VehicleMapComponent } from './components/vehicle-map/vehicle-map.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { VehicleInfoComponent } from './components/vehicle-info/vehicle-info.component';
import { VehicleDrivebookComponent } from './components/vehicle-drivebook/vehicle-drivebook.component';
import { VehicleDrivebookFilterComponent } from './components/vehicle-drivebook-filter/vehicle-drivebook-filter.component';
import { DrivebookDetailComponent } from './components/drivebook-detail/drivebook-detail.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ForgotPasswordSetComponent } from './components/forgot-password-set/forgot-password-set.component';
import { HeaderComponent } from './components/header/header.component';

const layouts = [
  LoginLayoutComponent,
  SiteLayoutComponent,
];
const pages = [
  HomeComponent,
  LoginComponent,
  ForgotPasswordComponent,
  ForgotPasswordSetComponent,
  DashboardComponent,
  VehicleDetailComponent,
  VehicleMapComponent,
  VehicleInfoComponent,
  VehicleDrivebookComponent,
  VehicleDrivebookFilterComponent,
  DrivebookDetailComponent,
  HeaderComponent,
];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    ...layouts,
    ...pages,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    CustomMaterialModule,
    UiComponentsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule,
  ],
  exports: [
    ...layouts,
    ...pages,
    UiComponentsModule,
  ],
  entryComponents: [
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'cs'
    },
  ]
})
export class PagesModule { }

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { DateAdapter } from '@angular/material/core';

import { registerLocaleData } from '@angular/common';

import localeCs from '@angular/common/locales/cs';
import localeCsExtra from '@angular/common/locales/extra/cs';
import localeSk from '@angular/common/locales/sk';
import localeSkExtra from '@angular/common/locales/extra/sk';
import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private selectedLang = 'cs';

  public get lang(): string {
    return this.selectedLang;
  }

  constructor(private translateService: TranslateService, private cookieService: CookieService, private dateAdapter: DateAdapter<any>) {
    registerLocaleData(localeCs, localeCsExtra);
    registerLocaleData(localeSk, localeSkExtra);
    registerLocaleData(localeEn, localeEnExtra);

    this.translateService.addLangs(['cs', 'en', 'sk']);
    this.translateService.setDefaultLang('cs');
    const cookieLang = this.cookieService.get('language');
    const defaultLang = 'cs';
    const selectedLang = cookieLang !== '' ? cookieLang : defaultLang;

    this.translateService.use(selectedLang);
    this.changeLanguage(selectedLang);
  }

  changeLanguage(lang) {
    const currentDate = new Date();
    const year = currentDate.getFullYear() + 10;
    currentDate.setFullYear(year);
    this.translateService.use(lang);
    this.cookieService.set('language', lang, currentDate, '/');
    this.dateAdapter.setLocale(lang);
    this.selectedLang = lang;
  }

  getLanguage() {
    return this.translateService.currentLang;
  }

  getDateFormat() {
    return this.translateService.currentLang === 'cs' ? 'dd.MM.yyyy' : 'MM/dd/yyyy';
  }
}

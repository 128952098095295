import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DividerComponent } from './components/ui/divider/divider.component';
import { CustomMaterialModule } from './material.module';
import { PasswordInputComponent } from './components/ui/password-input/password-input.component';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TimestampToStringPipe } from './libs/pipes/timestamp-to-string.pipe';
import { StringToTimestampPipe } from './libs/pipes/string-to-timestamp.pipe';
import { MinutesToTimePipe } from './libs/pipes/minutes-to-time';
import { DurationPipe } from './libs/pipes/duration-format';
import { IsVehicleOnlinePipe } from './libs/pipes/is-vehicle-online.pipe';
import { SpeedStrokeWidthPipe } from './libs/pipes/speed-stroke-width.pipe';
import { SpeedColorPipe } from './libs/pipes/speed-color.pipe';
import { VehicleIconPipe } from './libs/pipes/vehicle-icon.pipe';
import { VehicleMapMarkerPipe } from './libs/pipes/vehicle-map-marker.pipe';
import { VehicleStatePipe } from './libs/pipes/vehicle-state.pipe';
import { IsGroupSelectedPipe } from './libs/pipes/is-group-selected.pipe';
import { SortVehiclesPipe } from './libs/pipes/sort-vehicles.pipe';

const components = [
  DividerComponent,
  PasswordInputComponent,
];

const pipes = [
  TimestampToStringPipe,
  StringToTimestampPipe,
  MinutesToTimePipe,
  DurationPipe,
  IsVehicleOnlinePipe,
  SpeedStrokeWidthPipe,
  SpeedColorPipe,
  VehicleIconPipe,
  VehicleMapMarkerPipe,
  VehicleStatePipe,
  IsGroupSelectedPipe,
  SortVehiclesPipe
];

const directives = [
];

const modules = [
  CustomMaterialModule
];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    ...components,
    ...pipes,
    ...directives
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ReactiveFormsModule,
    MatInputModule,
    ...modules
  ],
  exports: [
    ...components,
    ...pipes,
    ...directives,
    ...modules,
  ],
  providers: [
  ],
  entryComponents: [
  ]
})
export class UiComponentsModule {}

<mat-card class="form-container small">
  <mat-progress-bar
    *ngIf="isLoading"
    mode="indeterminate"
    color="accent">
  </mat-progress-bar>
  <div class="row v-center between">
    <div class="row v-center">
      <mat-icon class="back" (click)="back()">arrow_back_ios</mat-icon>
      <ui-divider [vertical]="true" [compact]="true"></ui-divider>
      <h3> {{ 'forgot-password.title' | translate }}   </h3>
    </div>
    <div>
      <span class="">
        <button mat-button
          class="language-selector"
          [matMenuTriggerFor]="langSelector"
          [matTooltip]="'language' | translate">
          {{ selectedLanguage | uppercase }}
          <mat-icon class="transparent">language</mat-icon>
        </button>
        <mat-menu #langSelector="matMenu"
          x-position="after"
          y-position="below">
          <button mat-menu-item
            (click)="changeLanguage('cs')">{{ 'language-cs' | translate }}</button>
          <button mat-menu-item
            (click)="changeLanguage('sk')">{{ 'language-sk' | translate }}</button>
          <button mat-menu-item
            (click)="changeLanguage('en')">{{ 'language-en' | translate }}</button>
        </mat-menu>
      </span>
    </div>
  </div>
  <p>{{ 'forgot-password.message' | translate }}</p>
  <ui-divider></ui-divider>
  <form [formGroup]="form" (ngSubmit)="sendForgotPasswordEmail()">
    <div class="column">
      <mat-form-field class="full-width">
        <input type="text" matInput formControlName="username" [placeholder]="'user.username' | translate">
      </mat-form-field>


      <button mat-raised-button type="submit" color="primary" class="main-button"
        [disabled]="isLoading || form.invalid">{{ 'forgot-password.send' | translate }}</button>
      </div>
    </form>
</mat-card>

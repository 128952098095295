
export interface ILoginUser {
  username: string;
  password: string;
  workspaceId: number;
}

export class IAddEditUser {
  groupId: number;
  personId: string;
  username: string;
  email?: string;
}

export class IUserInfo {
  email: string;
  username: string;
  role: string;
}

export class IUserInfoDTO {
  id: number;
  username: string;
  emailAddress?: string;
  phoneNumber?: string;
  userGroupId: number;
  userGroupName: string;
  groupRightsId: number;
  groupRightsName: string;
  hasFilledProfile: boolean;
  gdpr: boolean;
}

export interface IUserContactDataRequest {
  id?: number;
  emailAddress: string;
  phoneNumber: string;
}

export interface IUserChangePassword {
  oldPassword: string;
  newPassword: string;
}

export interface IUserSetChangePassword {
  token: string;
  newPassword: string;
}

export class IUserLoginInfo {
  username: string;
  token: string;
  role: string;
}

<div class="map-container" *ngIf="!!selectedVehicles">
  <agm-map #AgmMap
    [ngClass]="{'limited': singleVehicle }"
    [longitude]="center?.longitude || INIT_POSITION.longitude"
    [latitude]="center?.latitude || INIT_POSITION.latitude"
    [mapTypeControl]="true"
    [mapTypeControlOptions]="mapTypeControlOptions"
    [gestureHandling]="'greedy'"
    [streetViewControl]="false"
    [zoom]="zoom || INIT_ZOOM">

    <ng-container *ngFor="let selectedVehicle of selectedVehicles">
      <agm-marker [latitude]="selectedVehicle?.position?.latitude"
        [longitude]="selectedVehicle?.position?.longitude"
        [iconUrl]="(selectedVehicle?.positionData | vehicleState: updateTriger)  | vehicleMapMarker"
        (markerClick)="clickedMarker(selectedVehicle?.carID)">

        <agm-info-window [disableAutoPan]="true" (infoWindowClose)="infoWindowClosed(selectedVehicle?.carID)" [isOpen]="infoWindowOpened?.includes(selectedVehicle?.carID)" >
          <div class="popup-content">
          <ng-container #popupData>
            <div class="simple-row first-row">
              <div class="popup-icon">
                <img *ngIf="selectedVehicle?.lastKnownDate | isVehicleOnline : updateTriger; else offlineVehicleImage"
                    [src]="selectedVehicle?.carID | vehicleIcon: selectedVehicle : true"
                    class="car-type-image"
                    height="20px"
                    matTooltipPosition="left"
                    [matTooltip]="selectedVehicle?.type">

                  <ng-template #offlineVehicleImage>
                    <img [src]="selectedVehicle?.carID | vehicleIcon: selectedVehicle : false"
                      class="car-type-image"
                      height="20px"
                      matTooltipPosition="left"
                      [matTooltip]="selectedVehicle?.type">
                  </ng-template>
              </div>
              <div class="header-labels">
                <h4 class="header">{{ selectedVehicle?.spz }}</h4>
                <div class="description"> {{ selectedVehicle?.toolTipText }}</div>
              </div>
              <div class="speed-container">
                <ng-container *ngIf="(selectedVehicle?.positionData | vehicleState: updateTriger) === vehicleStates.KEY_OFF_SPEED_ON_DATA_OFF || (selectedVehicle?.positionData | vehicleState: updateTriger) === vehicleStates.KEY_OFF_SPEED_ON_DATA_ON;else activeSpeedPopup">
                  <h4 class="speed not-moving">0 km/h</h4>
                </ng-container>
                <ng-template #activeSpeedPopup>
                  <h4 class="speed" [ngClass]="{ 'not-moving': !selectedVehicle?.speed || selectedVehicle?.speed < 1}"> {{ !!selectedVehicle?.speed ?  selectedVehicle?.speed : 0 }} km/h</h4>
                </ng-template>

                <p class="speed time" [ngClass]="{ 'not-moving': !selectedVehicle?.speed || selectedVehicle?.speed < 1}" > {{selectedVehicle?.lastKnownDate | date:"d.L.yy HH:mm"}}</p>
              </div>
            </div>
          </ng-container>
        </div>
        </agm-info-window>
      </agm-marker>

      <ng-container *ngFor="let line of liveLines; trackBy: trackByFunctionPolyline">
        <ng-container *ngFor="let path of line.paths; let i = index">
          <agm-polyline [strokeWeight]="path?.speed | speedStrokeWidth"
            [strokeColor]="path?.speed | speedColor">

            <agm-marker
              [latitude]="path?.latitude"
              [longitude]="path?.longitude"
              [visible]="false"
              >
            </agm-marker>

            <agm-polyline-point
              [latitude]="path?.latitude"
              [longitude]="path?.longitude">
            </agm-polyline-point>

            <agm-polyline-point
              *ngIf="line?.paths[i + 1] as nextPath"
              [latitude]="nextPath?.latitude"
              [longitude]="nextPath?.longitude">
            </agm-polyline-point>
          </agm-polyline>
        </ng-container>
      </ng-container>
    </ng-container>
  </agm-map>
  <div class="info row v-center between" *ngIf="singleVehicle">
    <div class="field">
      <div class="small light">{{ "vehicles.actual-speed" | translate }}</div>
      <ng-container *ngIf="(singleVehicle?.positionData | vehicleState: updateTriger) === vehicleStates.KEY_OFF_SPEED_ON_DATA_OFF || (singleVehicle?.positionData | vehicleState: updateTriger) === vehicleStates.KEY_OFF_SPEED_ON_DATA_ON;else activeSpeed">
        <h2 class="light">0 km/h</h2>
      </ng-container>
      <ng-template #activeSpeed>
        <h2 class="light">{{ singleVehicle?.speed  || 0 }} km/h</h2>
      </ng-template>
    </div>
    <div class="field">
      <div class="small light">{{ "vehicles.last-update" | translate }}</div>
      <h3 class="light">{{ singleVehicle?.lastKnownDate | date: "dd.MM. HH:mm" }}</h3>
    </div>
  </div>
</div>

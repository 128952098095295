<mat-card class="form-container small">
  <mat-progress-bar *ngIf="isLoading"
    mode="indeterminate"
    color="accent"></mat-progress-bar>
  <div class="row v-center between">
    <div>
      <h3> {{ 'login' | translate }} </h3>
    </div>
    <div>
      <span class="">
        <button mat-button
          class="language-selector"
          [matMenuTriggerFor]="langSelector"
          [matTooltip]="'language' | translate">
          {{ selectedLanguage | uppercase }}
          <mat-icon class="transparent">language</mat-icon>
        </button>
        <mat-menu #langSelector="matMenu"
          x-position="after"
          y-position="below">
          <button mat-menu-item
            (click)="changeLanguage('cs')">{{ 'language-cs' | translate }}</button>
          <button mat-menu-item
            (click)="changeLanguage('sk')">{{ 'language-sk' | translate }}</button>
          <button mat-menu-item
            (click)="changeLanguage('en')">{{ 'language-en' | translate }}</button>
        </mat-menu>
      </span>
    </div>
  </div>
  <form [formGroup]="form"
    class="form"
    (ngSubmit)="login()">

    <div class="column">
      <mat-form-field>
        <input type="text"
          matInput
          formControlName="username"
          [placeholder]="'user.username' | translate">
      </mat-form-field>

      <ui-password-input [value]="''"
        [placeholder]="'password'"
        [name]="'password'"
        (onInputChange)="changeFormValue($event.name, $event.value)">
      </ui-password-input>

      <ui-divider></ui-divider>

      <p *ngIf="errorLogin"
        class="error-label centered">{{ 'error-login-label' | translate }}</p>

      <button mat-raised-button
        type="submit"
        color="primary"
        class="main-button"
        [disabled]="isLoading || form.invalid">{{ 'login' | translate }}</button>

      <ui-divider></ui-divider>
      <button mat-button
        type="button"
        class="main-button"
        (click)="onForgotPassword()">{{ 'login.forgot-password' | translate }}</button>
    </div>
  </form>
</mat-card>

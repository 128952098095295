export enum DriveType {
  Private = 1,
  Business = 0,
  All = -1
}

export interface IDriveBookTotalsDTO {
  totalDistance: number;
  totalDuration: number;
  totalNumberOfTrips: number;
  privateDistance: number;
  privateDuration: number;
  privateNumberOfTrips: number;
  businessDistance: number;
  businessDuration: number;
  businessNumberOfTrips: number;
}

export interface IGroupedDriveBooksDTO extends IDriveBookTotalsDTO {
  driveBooks: IDriveBookDTO[];
}

export interface IDriveBookDTO {
  driveBookId: number;
  driverId: number;
  carId: number;
  purpose: string;
  traceDistance: number;
  driveType: DriveType;
  fuelRefillLitres: number;
  fuelRefillPrice: number;
  receipt: any | null;
  tachoStart: number;
  tachoStop: number;
  dateTimeStart: string;
  dateTimeStop: string;
  gpsPosStartX: number;
  gpsPosStartY: number;
  gpsPosStopX: number;
  gpsPosStopY: number;
  startDescription: string | null;
  stopDescription: string | null;
  maxSpeed: number;
  speeding: number;
  note: string | null;
  deleted: boolean;
  newTacho: number;
  closed: boolean;
  newDistance: number;
  totalTime: number;
}

export interface IDriveBookDayDTO {
  day: string;
  driveBooks: IGroupedDriveBooksDTO;
  selectAllIsChecked?: boolean;
}

export interface IDriveBooksDTO extends IDriveBookTotalsDTO {
  driveBooks: IDriveBookDayDTO[];
}

export interface IDriveBookPathDataDTO {
  date: any;
  speed: number;
  latitude: number;
  longitude: number;

}

export interface IDriveBookPathWrapperDTO {
  drivebookId: number;
  paths: IDriveBookPathDataDTO[];
}

export enum DriveTypeString {
  All = 'all',
  Business = 'business',
  Private = 'private'
}

export interface IDriveBookFilter {
  intervalFrom: string;
  intervalTo: string;
  type: DriveType;
  driver: number;
}
export interface IPathDTO {
  latitude: number;
  longitude: number;
  date: string;
  speed: number;
  visible: boolean;
}

export interface IDriveBookPathDTO {
  driveBookPath: {
    drivebookId: number;
    path: IPathDTO[];
  };
}

export interface IDriveBookDetailDTO {
  driveBook: IDriveBookDTO;
}

export interface IPutDriveBookDTO {
  drivebookId: number;
  type: number;
  driverId: number;
  drivePurpose: string;
  distance: number;
  tachoChange: number;
  fuelAmount: number;
  fuelPrice: number;
  maxSpeed: number;
  averageSpeed: number;
  bill: string;
  startDate: string;
  startTacho: number;
  startPlace: string;
  stopDate: string;
  stopTacho: number;
  stopPlace: string;
  note: string;
}

export interface INewDriveBookDTO {
  carId: number;
  type: number;
  driverId: number;
  drivePurpose: string;
  distance: number;
  tachoChange: number;
  fuelAmount: number;
  fuelPrice: number;
  maxSpeed: number;
  averageSpeed: number;
  bill: string;
  startDate: string;
  startTacho: number;
  startPlace: string;
  stopDate: string;
  stopTacho: number;
  stopPlace: string;
  note: string;
}

export interface ICarDriverDTO {
  nCarID: number;
  nPersonID: number;
  strName: string;
  strSurname: string;
}

export interface ICarDriversDTO {
  carId: number;
  drivers: {
    drivers: ICarDriverDTO[];
  };
}

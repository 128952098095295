import { OnInit, OnDestroy, Injector } from '@angular/core';
import { CommonComponent } from './common.component';
import { VehicleQuery } from 'src/app/store/vehicle/vehicle.query';
import { VehicleService } from 'src/app/store/vehicle/vehicle.service';
import { ICarDTO, ICarPositionExtra } from 'src/app/store/vehicle/vehicle.model';

export class CommonVehicleComponent extends CommonComponent implements OnInit, OnDestroy {
  INIT_ZOOM = 8;
  INIT_POSITION = {
    latitude: 49.80376388888889,
    longitude: 15.474913888888889
  };
  vehicleId: number;
  drivebookId: number;
  vehicles: ICarDTO[];
  activeVehicle: ICarDTO;
  activeVehiclePosition: ICarPositionExtra;
  vehicleQuery: VehicleQuery;
  vehicleService: VehicleService;
  constructor(injector: Injector) {
    super(injector);
    this.vehicleId = Number.parseInt(this.activeRoute.snapshot.params.vehicleId, 10);
    this.drivebookId = Number.parseInt(this.activeRoute.snapshot.params.drivebookId, 10);
    this.vehicleQuery = injector.get(VehicleQuery);
    this.vehicleService = injector.get(VehicleService);
    this.subscriptions.add(this.vehicleQuery.allCars$.subscribe((vehicles) => {
      this.vehicles = vehicles;
    }));
    this.setIsLoading(true);
    this.vehicleService.fetchGroupsWithCars(this.onDataLoaded, this.onDataError);
  }

  ngOnInit() {
  }

  public onDataLoaded = (): void => {
    this.setIsLoading(false);
  }

  public onDataError = (): void => {
    this.setIsLoading(false);
  }

  setActiveVehicle = (onActiveVehicle: () => void, onErrorActiveVehicle: () => void): void => {
    if (!this.vehicles) {
      return;
    }
    this.activeVehicle = this.vehicles.find(vehicle => vehicle.carID === this.vehicleId);
    if (!this.activeVehicle || !this.vehicleId) {
      onErrorActiveVehicle();
    } else {
      onActiveVehicle();
    }
  }
}

import { Injectable } from '@angular/core';
import { VehicleStore, createInitialState } from './vehicle.store';
import { combineLatest, Observable } from 'rxjs';
import * as moment from 'moment';
import { ICarPositionExtra, ICarGroupDTO, ICarLastPositionDTO, ICarsCurrentPosition, ICarLastTachoValueDTO } from './vehicle.model';
import { VehicleHttpService } from './vehicle-http.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Injectable({ providedIn: 'root' })
export class VehicleService {

  public constructor(
    private vehicleStore: VehicleStore,
    private vehicleHttpService: VehicleHttpService,
    private utilityService: UtilitiesService,
  ) { }

  public fetchGroupsWithCars(onSucceeded: () => void, onError: () => void, force = false): void {
    this.vehicleStore.update((state) => {
      if (force || !state.carGroups) {
        this.vehicleHttpService.fetchCarGroups().subscribe((carGroups) => {
          this.vehicleStore.update({ carGroups: carGroups.carGroups, loading: false });
          this.fetchLastAddresses(carGroups.carGroups);
          onSucceeded();
        }, () => {
          onError();
        });
      } else {
        onSucceeded();
      }
      return { ...state };
    });
  }

  public fetchLastAddresses(carGroups: ICarGroupDTO[]): void {
    if (carGroups.length !== 0) {
      const obs: Observable<ICarLastPositionDTO>[] = [];
      for (const cargroup of carGroups) {
        for (const car of cargroup.cars) {
          obs.push(this.vehicleHttpService.fetchCarLastAddress(car.carID));
        }
      }
      combineLatest(obs).subscribe((positions) => {
        const updatedCarGroups = [];
        for (const cargroup of carGroups) {
          const newCarGroup = { ...cargroup, cars: [] };
          for (const car of cargroup.cars) {
            const positionDTO = positions.find((position) => position.carId === car.carID);
            const timeOfDrive = moment.utc(positionDTO.timeOfDrive).local();
            newCarGroup.cars.push({
              ...car, lastKnownPositionAdress: positionDTO.address,
              lastKnownDate: moment.utc(car.lastKnownDate).local().toISOString() || timeOfDrive
            });
          }
          updatedCarGroups.push(newCarGroup);
        }
        this.vehicleStore.update({ carGroups: updatedCarGroups });
      });
    }
  }

  public fetchVehicleConcise(vehicleId: number, onSucceeded: () => void, onError: () => void): void {
    this.vehicleStore.update({ vehicleInfo: null });
    this.fetchCarLastTachoValue(vehicleId);
    this.vehicleHttpService.fetchCarConcise(vehicleId).subscribe((vehicleInfo) => {
      this.vehicleStore.update({ vehicleInfo });
      onSucceeded();
    }, () => {
      onError();
      this.vehicleStore.update({ vehicleInfo: null });
    });
  }

  public setCurrentPositions(positions: ICarsCurrentPosition): void {
    this.vehicleStore.update((state) => {
      state.carGroups?.forEach((group) => {
        group?.cars?.forEach((vehicle) => {
        const position = positions.lastPositions.find(pos => vehicle.carID === pos.carId);
        if (!!position) {
          const prevPoint: ICarPositionExtra = {
            latitude: vehicle.position.latitude,
            longitude: vehicle.position.longitude,
            lastKnownDate: vehicle.lastKnownDate,
            lastKnownPositionAdress: null,
            carId: null,
            isOnline: null,
            previousPositions: null,
            speed: vehicle.speed,
            isKeyOn: position.isKeyOn,
          };
          const currentPoint: ICarPositionExtra = {
            latitude: position.latitude,
            longitude: position.longitude,
            lastKnownDate: moment.utc(position.lastKnownDate).local().toISOString(),
            lastKnownPositionAdress: null,
            carId: null,
            isOnline: null,
            previousPositions: null,
            speed: null,
            isKeyOn: position.isKeyOn,
          };
          vehicle.speed = this.utilityService.calculateSpeed(prevPoint, currentPoint);
          vehicle.position.latitude = position.latitude;
          vehicle.position.longitude = position.longitude;
          vehicle.positionData = currentPoint;
          vehicle.positionData.speed = vehicle.speed;
          vehicle.lastKnownDate = moment.utc(position.lastKnownDate).local().toISOString();
        }
      });
    });
    });
  }

  public fetchVehicleColors(): void {
    this.vehicleHttpService.fetchColors().subscribe((vehicleColors) => {
      this.vehicleStore.update({ vehicleColors });
    }, () => {
    });
  }

  public fetchVehicleBodies(): void {
    this.vehicleHttpService.fetchCarBodies().subscribe((vehicleBodies) => {
      this.vehicleStore.update({ vehicleBodies });
    }, () => {
    });
  }

  public fetchVehicleFuels(): void {
    this.vehicleHttpService.fetchFuels().subscribe((vehicleFuels) => {
      this.vehicleStore.update({ vehicleFuels });
    }, () => {
    });
  }

  public fetchDriversByVehicle(vehicleId: number): void {
    this.vehicleHttpService.fetchDriversByCarId(vehicleId).subscribe((vehicleDrivers) => {
      this.vehicleStore.update({ vehicleDrivers });
    }, () => {
    });
  }

  public fetchCarLastTachoValue(vehicleId: number): void {
    this.vehicleHttpService.fetchCarLastTachoValue(vehicleId).subscribe((vehicleLastTacho) => {
      this.vehicleStore.update({ vehicleLastTacho });
    }, () => {
    });
  }

  public clearStore(): void {
    this.vehicleStore.update(createInitialState());
  }
}

import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { IAppState, AppStore } from './app.store';

@Injectable({ providedIn: 'root' })
export class AppQuery extends Query<IAppState> {

  public readonly isLoading$ = this.select((state) => state.pendingRequests > 0);
  public readonly title$ = this.select((state) => state.title);
  public readonly subtitle$ = this.select((state) => state.subtitle);

  public constructor(protected store: AppStore) {
    super(store);
  }
}

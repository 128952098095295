import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IUserInfo, IUserInfoDTO, IUserLoginInfo, ILoginUser, IUserSetChangePassword } from './auth.model';
import { AuthHttpService } from './auth-http.service';
import { VehicleService } from '../vehicle/vehicle.service';
import { DriveBookService } from '../drive-book/drive-book.service';
import { AuthStore } from './auth.store';
import { LoggerService } from 'src/app/services/logger.service';
import { MapService } from '../map/map.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private authHttpService: AuthHttpService,
    private vehicleService: VehicleService,
    private drivebookService: DriveBookService,
    private loggerService: LoggerService,
    private mapService: MapService,
    private authStore: AuthStore) {
    const userToken = JSON.parse(localStorage.getItem('auth_token'));
    if (userToken !== null) {
      const userInfo: IUserInfo = {
        email: this.getEmail(),
        username: this.getUsername(),
        role: '',
      };
      this.authStore.update({ userInfo });
    }
  }
  public refreshToken(): void {
    this.authHttpService.refreshToken().subscribe((response) => {
      this.loggerService.logInfo(`RefreshToken: new token requested at ${ new Date().toLocaleTimeString() }`);
      localStorage.setItem('auth_token', JSON.stringify(response.accessToken));
    }, () => { });
  }

  public getToken() {
    return JSON.parse(localStorage.getItem('auth_token'));
  }

  public getEmail() {
    return JSON.parse(localStorage.getItem('auth_email'));
  }

  public getUsername() {
    return JSON.parse(localStorage.getItem('auth_username'));
  }

  public logoutUser() {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('auth_email');
    localStorage.removeItem('auth_username');
    this.clearAllStores();
  }

  private clearAllStores(): void {
    this.authStore.update({ userInfo: null });
    this.vehicleService.clearStore();
    this.drivebookService.clearStore();
    this.mapService.clearStore();
  }

  public loadAuthData(authHttpService: AuthHttpService): Observable<IUserInfoDTO> {
    this.fetchContactData();
    return authHttpService.loadAuthData(this.getToken())
      .pipe(map((response) => {
        const user: IUserInfo = {
          email: response.emailAddress,
          role: response.userGroupName,
          username: response.username
        };
        this.authStore.update({ userInfo: user });
        return response;
      }));
  }

  public fetchContactData() {
    this.authHttpService.fetchContactData().subscribe(data => {
      this.authStore.update({ profile: data });
    }
    );
  }

  public loginUser(user: ILoginUser, onSucceed: () => void, onError: (error: any) => void): void {
    this.authHttpService.loginUser(user).subscribe(response => {
      const loginUser = new IUserLoginInfo();
      loginUser.username = response.username;
      loginUser.token = response.accessToken;
      localStorage.setItem('auth_token', JSON.stringify(response.accessToken));
      localStorage.setItem('auth_username', JSON.stringify(response.username));
      this.loadAuthData(this.authHttpService);
      onSucceed();
    }, error => {
      onError(error);
    });
  }

  public isLoggedIn() {
    return localStorage.getItem('auth_username') != null;
  }

  public sendForgotPassword(username: string, onSuceeded: () => void, onError: () => void): void {
    this.authHttpService.sendForgotPassword(username).subscribe((res) => {
      onSuceeded();
    }, (error) => {
      onError();
    });
  }

  public sendSetForgotPassword(payload: IUserSetChangePassword, onSuceeded: () => void, onError: () => void): void {
    this.authHttpService.setForgotPassword(payload).subscribe((res) => {
      onSuceeded();
    }, (error) => {
      onError();
    });
  }
}

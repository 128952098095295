import { Pipe, PipeTransform } from '@angular/core';
import { Color } from 'src/app/components/drivebook-detail/drivebook-detail.component';
import { SPEED_LIMIT_TOWN, SPEED_LIMIT_ROADS } from '../constants/constants';

@Pipe({
  name: 'speedColor',
  pure: true
})
export class SpeedColorPipe implements PipeTransform {

  public transform(value: number): string {
    if (value <= SPEED_LIMIT_TOWN) {
      return Color.Green;
    } else if (value > SPEED_LIMIT_TOWN && value <= SPEED_LIMIT_ROADS) {
      return Color.Orange;
    } else {
      return Color.Red;
    }
  }
}

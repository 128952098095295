import { Pipe, PipeTransform } from '@angular/core';
import { ICarDTO } from 'src/app/store/vehicle/vehicle.model';

@Pipe({
  name: 'sortVehicles',
  pure: true
})
export class SortVehiclesPipe implements PipeTransform {

  public transform(value: ICarDTO[]): ICarDTO[] {
    return value?.slice().sort((n1, n2) => {
        if (n1.spz > n2.spz) {
          return 1;
        }
        if (n1.spz < n2.spz) {
          return -1;
        }
        return 0;
      });
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { SPEED_LIMIT_TOWN, SPEED_LIMIT_ROADS } from '../constants/constants';

@Pipe({
  name: 'speedStrokeWidth',
  pure: true
})
export class SpeedStrokeWidthPipe implements PipeTransform {
  public transform(value: number): number {
    if (value <= SPEED_LIMIT_TOWN) {
      return 4;
    } else if (value > SPEED_LIMIT_TOWN && value <= SPEED_LIMIT_ROADS) {
      return 5;
    } else {
      return 5;
    }
  }
}

<div class="wrapper" *ngIf="!!activeVehicle">
  <div class="filter-container">
      <form [formGroup]="form" class="drive-book-filter-form">
        <div class="type-header">
          {{ 'drive-book.drives.type' | translate }}
        </div>

        <div class="filter-input">
          <mat-radio-group formControlName="type" class="filter-type">
              <mat-radio-button [value]="driveTypes.All" color="primary">
                {{ 'drive-book.totals.all' | translate }}
              </mat-radio-button>

              <mat-radio-button [value]="driveTypes.Private" color="primary">
                {{ 'drive-book.totals.private' | translate }}
              </mat-radio-button>

              <mat-radio-button [value]="driveTypes.Business" color="primary">
                {{ 'drive-book.totals.business' | translate }}
              </mat-radio-button>
          </mat-radio-group>
        </div>
        <ui-divider></ui-divider>

        <mat-form-field class="filter-input">
          <input matInput formControlName="intervalFrom" [matDatepicker]="filterDateFrom" [placeholder]="'filter.from' | translate" (click)="filterDateFrom.open()">
          <mat-datepicker-toggle matSuffix [for]="filterDateFrom"></mat-datepicker-toggle>
          <mat-datepicker #filterDateFrom></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="filter-input">
          <input matInput formControlName="intervalTo" [matDatepicker]="filterDateTo" [min]="form.value['intervalFrom']" [placeholder]="'filter.to' | translate"  (click)="filterDateTo.open()">
          <mat-datepicker-toggle matSuffix [for]="filterDateTo"></mat-datepicker-toggle>
          <mat-datepicker #filterDateTo></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="filter-input" *ngIf="!!drivers && drivers?.length > 0">
          <mat-label>{{ 'drive-book.edit-form.drive.driver' | translate }}</mat-label>
          <mat-select formControlName="driver">
            <mat-option *ngFor="let driver of drivers" [value]="driver.nPersonID">
              {{ driver.strSurname | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
      <button class="filter-input" type="button" mat-button mat-raised-button color="primary" [disabled]="!activeVehicle" (click)="onFilterDrivebooks()">
        {{ 'drive-book.search' | translate }}
      </button>
    </div>
  </div>

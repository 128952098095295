<div class="container"
  *ngIf="noPathData || drivebookDetail">
  <agm-map #AgmMap
    *ngIf="!!centerPoint"
    [longitude]="centerPoint?.longitude || INIT_POSITION.longitude"
    [latitude]="centerPoint?.latitude || INIT_POSITION.latitude"
    [mapTypeControl]="false"
    [mapTypeControlOptions]="mapTypeControlOptions"
    [gestureHandling]="'greedy'"
    [streetViewControl]="false"
    [zoom]="zoom || INIT_ZOOM"
    class="map"
    [ngClass]="{'expanded': detailExpanded, 'collapsed': !detailExpanded }">
    <ng-container *ngFor="let path of drivebookPath?.driveBookPath?.path; let i = index">
      <agm-polyline [strokeWeight]="path?.speed | speedStrokeWidth"
        [strokeColor]="path?.speed | speedColor">
        <agm-icon-sequence *ngIf="i === drivebookPath?.driveBookPath?.path?.length - 2"
          [fixedRotation]="false"
          path="FORWARD_CLOSED_ARROW">
        </agm-icon-sequence>

        <agm-marker [latitude]="path?.latitude"
          [longitude]="path?.longitude"
          [visible]="false">
        </agm-marker>

        <agm-polyline-point [latitude]="path?.latitude"
          [longitude]="path?.longitude">
        </agm-polyline-point>

        <agm-polyline-point *ngIf="drivebookPath?.driveBookPath?.path[i + 1] as nextPath"
          [latitude]="nextPath.latitude"
          [longitude]="nextPath.longitude">
        </agm-polyline-point>
      </agm-polyline>
    </ng-container>
  </agm-map>

  <div class="info-wrapper"
    [ngClass]="{'expanded': detailExpanded, 'collapsed': !detailExpanded }"
    (swipe)="swipeUp()"
    (swipedown)="swipeDown()">
    <ng-container *ngIf="noPathData">
      <div class="notification-bar warning"> {{'drive-book.error' | translate }}</div>
      <ui-divider></ui-divider>
    </ng-container>

    <div *ngIf="!!drivebookPath"
      class="expander"
      [ngClass]="{'expanded': detailExpanded, 'collapsed': !detailExpanded }"
      (click)="toogleDetailView()">
      <mat-icon *ngIf="!detailExpanded">keyboard_arrow_up</mat-icon>
      <mat-icon *ngIf="detailExpanded">keyboard_arrow_down</mat-icon>
    </div>
    <div class="field">
      <div class="small light"> {{ drivebookDetail?.dateTimeStart | stringToTimestamp | date:"dd. MM. yyyy HH:mm"}}</div>
      <b class="small light">{{ drivebookDetail?.startDescription }}</b>
    </div>

    <ui-divider [compact]="true"></ui-divider>
    <div class="field">
      <div class="small light">{{ drivebookDetail?.dateTimeStop | stringToTimestamp | date:"dd. MM. yyyy HH:mm"}}</div>
      <b class="small light">{{ drivebookDetail?.stopDescription }}</b>
    </div>

    <ui-divider [compact]="true"></ui-divider>
    <div class="between"
        [ngClass]="{ 'row': !detailExpanded}">
        <div class="field">
          <div class="small light">{{ "drive-book.totals.drive-time" | translate }}</div>
          <b class="small light">{{ drivebookDetail?.totalTime  | durationTime }}</b>
        </div>

        <ui-divider [compact]="true"></ui-divider>
        <div class="field">
          <div class="small light">{{ "drive-book.drives.distance" | translate }}</div>
          <b class="small light">{{ drivebookDetail?.traceDistance | number: "1.0-1" }} km</b>
        </div>

        <ui-divider [compact]="true"></ui-divider>
        <div class="field" *ngIf="!editMode">
          <div class="small light">{{ "drive-book.drives.type" | translate }}</div>
          <ng-container [ngSwitch]="drivebookDetail?.driveType">
            <b class="small light"
              *ngSwitchCase="driveTypes.Business"> {{ 'drive-book.type.business' | translate }}</b>
            <b class="small light"
              *ngSwitchCase="driveTypes.Private"> {{ 'drive-book.type.private' | translate }}</b>
          </ng-container>
        </div>
      </div>

    <ng-container *ngIf="!editMode">
      <ui-divider [compact]="true"></ui-divider>
      <div class="field" *ngIf="drivers?.length > 0">
        <div class="small light">{{ "drive-book.edit-form.drive.driver" | translate }}</div>
        <b class="small light">{{ getDriversName(drivebookDetail?.driverId) || '-' }} </b>
      </div>

      <ui-divider [compact]="true"></ui-divider>
      <div class="field">
        <div class="small light">{{ "drive-book.edit-form.drive.purpose" | translate }}</div>
        <b class="small light">{{ drivebookDetail?.purpose || '-' }} </b>
      </div>

      <ui-divider></ui-divider>
      <p class="title"> {{ 'drive-book.edit-form.fuel.title' | translate }} </p>
      <ui-divider [compact]="true"></ui-divider>
      <div class="row between">
        <div class="field">
          <div class="small light">{{ "drive-book.edit-form.other.fuel-refill-litres" | translate }}</div>
          <b class="small light">
            {{ drivebookDetail?.fuelRefillLitres > 0 ? drivebookDetail?.fuelRefillLitres + ' l' : '-' }} 
          </b>
        </div>
        <div class="field">
          <div class="small light">{{ "drive-book.edit-form.other.fuel-refill.price" | translate }}</div>
          <b class="small light">{{ drivebookDetail?.fuelRefillPrice > 0 ? drivebookDetail?.fuelRefillPrice + ' CZK' : '-' }} </b>
        </div>
      </div>
      <ui-divider></ui-divider>
    </ng-container>

    <ng-container *ngIf="editMode">
      <form *ngIf="form"
        [formGroup]="form">
        <mat-form-field class="form-field">
          <mat-label>{{ 'drive-book.edit-form.drive.drive-type' | translate }}</mat-label>
          <mat-select formControlName="driveType">
            <mat-option [value]="driveTypes.Private">
              {{ 'drive-book.type.private' | translate }}
            </mat-option>
            <mat-option [value]="driveTypes.Business">
              {{ 'drive-book.type.business' | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="form-field" *ngIf="drivers.length > 0">
          <mat-label>{{ 'drive-book.edit-form.drive.driver' | translate }}</mat-label>
          <mat-select formControlName="driver">
            <mat-option *ngFor="let driver of drivers"
              [value]="driver?.nPersonID">
              {{ driver?.strSurname | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="form-field">
          <input matInput
            [placeholder]="'drive-book.edit-form.drive.purpose' | translate"
            formControlName="purpose">
        </mat-form-field>

        <mat-form-field class="form-field">
          <input matInput
            [placeholder]="'drive-book.edit-form.stop.tachometer' | translate"
            formControlName="note">
        </mat-form-field>

        <ui-divider></ui-divider>
        <p class="title"> {{ 'drive-book.edit-form.fuel.title' | translate }} </p>
        <ui-divider [compact]="true"></ui-divider>

        <div class="row between">
          <mat-form-field class="form-field half">
            <input matInput
              [placeholder]="'drive-book.edit-form.other.fuel-refill-litres' | translate"
              type="number"
              formControlName="fuelRefillLitres">
            <div matSuffix
              class="mat-small">{{ 'drive-book.edit-form.other.litres' | translate}}</div>
          </mat-form-field>

          <mat-form-field class="form-field half">
            <input matInput
              [placeholder]="'drive-book.edit-form.other.fuel-refill.price' | translate"
              type="number"
              formControlName="fuelRefillPrice">
            <div matSuffix
              class="mat-small">CZK</div>
          </mat-form-field>
        </div>
      </form>
    </ng-container>
    <div class="row between"
      *ngIf="editMode">
      <button class="form-field half"
        type="button"
        (click)="cancelEdit()"
        mat-raised-button>
        {{ 'form-actions.cancel' | translate }}
      </button>
      <button class="form-field half"
        type="submit"
        (click)="onEdit()"
        mat-raised-button
        color="primary"
        [disabled]="form.invalid">
        {{ 'form-actions.save' | translate }}
      </button>
    </div>
    <ng-container *ngIf="!editMode">
      <button class="form-field"
      *ngIf="userInfo?.role === 'User-web-edit' || userInfo?.role === 'User-web-admin' || userInfo?.role === 'User-web-driver'; else notAllowedEdit"
        type="button"
        color="primary"
        (click)="toogleEditView()"
        mat-raised-button>
        {{ 'form-actions.edit' | translate }}
      </button>

      <ng-template #notAllowedEdit>
        <button class="form-field"
          disabled
          type="button"
          color="primary"
          mat-raised-button>
          {{ 'form-actions.edit' | translate }}
        </button>
      </ng-template>
    </ng-container>
  </div>
</div>

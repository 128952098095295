import { Component, OnInit, Injector } from '@angular/core';
import { CommonVehicleComponent } from '../common/common-vehicle.component';
import { ICarConciseDTO } from 'src/app/store/vehicle/vehicle.model';

@Component({
  selector: 'app-vehicle-info',
  templateUrl: './vehicle-info.component.html',
  styleUrls: ['./vehicle-info.component.scss']
})
export class VehicleInfoComponent extends CommonVehicleComponent implements OnInit {
  vehicleInfo: ICarConciseDTO;
  vehicleInfoFormatted: any[];
  lastTachoValue: number;

  constructor(injector: Injector) {
    super(injector);
    this.setTitle('page-title.car-detail');
    this.setIsLoading(true);

    this.subscriptions.add(this.vehicleQuery.vehicleInfo$.subscribe((vehicleInfo) =>
      this.vehicleInfo = vehicleInfo
    ));

    this.subscriptions.add(this.vehicleQuery.vehicleLastTacho$.subscribe((lastTachoValue) =>
    this.lastTachoValue = lastTachoValue?.tachoValue
  ));

    this.subscriptions.add(this.vehicleQuery.allCars$.subscribe(() =>
      this.setActiveVehicle(this.onActiveVehicle, this.onErrorActiveVehicle)
    ));
  }

  ngOnInit(): void {
    this.vehicleService.fetchVehicleConcise(this.vehicleId, this.fillFormData, this.onError);
  }

  fillFormData = (): void => {
    if (!!this.vehicleInfo) {
      this.vehicleInfoFormatted = [
        { label: 'car-detail.plate-number', value: this.vehicleInfo.spz, translateValue: false },
        { label: 'car-detail.description', value: this.vehicleInfo.toolTipText, translateValue: false },
        { label: 'car-detail.entity-serial-number', value: this.vehicleInfo.entitySerialNumber, translateValue: false },
        { label: 'car-detail.fuel', value: this.vehicleInfo?.fuel?.keyVal, translateValue: true },
        { label: 'car-detail.color', value: this.vehicleInfo?.carColor?.keyVal, translateValue: true },
        { label: 'car-detail.ccs-card-number', value: this.vehicleInfo.ccsCardNumber, translateValue: false },
        { label: 'vehicles.last-position', value: this.activeVehicle?.lastKnownPositionAdress, translateValue: false },
        { label: 'car-detail.last-tacho', value: this.numberWithCommas(Math.round(this.lastTachoValue)) + ' km', translateValue: false },
      ];
    }
    this.setIsLoading(false);
  }

  onError = (): void => {
  }

  onActiveVehicle = (): void => {
    this.setTitle('page-title.car-detail', this.activeVehicle);
    this.fillFormData();
  }

  onErrorActiveVehicle = (): void => {
  }

  public numberWithCommas(value) {
    const parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }
}

import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { CommonComponent } from '../common/common.component';
import { CarsSignalRService } from 'src/app/services/carsSignalR.service';
import { AuthService } from 'src/app/store/auth/auth.service';
import { UrlRoutes } from 'src/app/app-routes';
import { WorkspaceStore } from 'src/app/store/workspace/workspace.store';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends CommonComponent implements OnInit, OnDestroy {

  constructor(
    public authService: AuthService,
    private signalRService: CarsSignalRService,
    injector: Injector,
    private workspaceStore: WorkspaceStore) {
    super(injector);
  }

  ngOnInit(): void {
    this.signalRService.startConnection();
  }

  ngOnDestroy(): void {
    this.signalRService.stopConnection();
  }

  logout(): void {
    this.signalRService.stopConnection();
    this.authService.logoutUser();
    this.navigate(UrlRoutes.urls.loginUrl);
  }

  onDashboard(): void {
    this.navigate(UrlRoutes.urls.dashboardUrl);
  }

  get smallLogoPath(): string {
    return './../../../' + this.workspaceStore.getValue().smallLogoPath;
  }

}

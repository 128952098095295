import { Component, OnInit, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { UrlRoutes } from 'src/app/app-routes';
import { AuthService } from 'src/app/store/auth/auth.service';
import { CommonComponent } from '../common/common.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent extends CommonComponent implements OnInit {

  constructor(private authService: AuthService, injector: Injector) {
    super(injector);
    if (this.isLoggedIn) {
      this.navigate(UrlRoutes.urls.dashboardUrl);
    }
  }

  ngOnInit() {
    this.initializeForm();
    this.setTitle('login.forgot-password');
  }

  initializeForm(): void {
    this.createForm({
      username: ['', [Validators.required]],
    });
  }

  sendForgotPasswordEmail(): void {
    this.setIsLoading(true);
    this.authService.sendForgotPassword(this.form.value.username, () => {
      this.showInfo(this.getTranslatedKey('forgot-password.change-successfull'));
      this.setIsLoading(false);
    }, () => {
      this.showError(this.getTranslatedKey('forgot-password.change-error'));
      this.setIsLoading(false);
    });
  }

  back(): void {
    this.navigate(UrlRoutes.urls.loginUrl);
  }
}

import { Component, Inject } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { WorkspaceStore } from 'src/app/store/workspace/workspace.store';
import { AppConfigService } from 'src/app/services/appConfig.service';

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0, top: '20vh' }),
        animate('600ms ease-out')
      ])
    ]),
    trigger('background', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(1000)
      ])
    ])
  ]
})
export class LoginLayoutComponent {

  constructor(
    public appConfigService: AppConfigService,
    private workspaceStore: WorkspaceStore) { }

  get contactCompanyUrl(): string {
    return this.workspaceStore.getValue().contactCompanyUrl;
  }

  get logoPath(): string {
    return './../../../' + this.workspaceStore.getValue().logoPath;
  }

  get backgroundImg(): string {
    return this.workspaceStore.getValue().loginBackgroundpath;
  }
}

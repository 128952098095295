import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface IAppState {
  pendingRequests: number;
  title: string;
  subtitle: string;
}

export function createInitialState(): IAppState {
  return {
    pendingRequests: 0,
    title: null,
    subtitle: '',
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'app' })
export class AppStore extends Store<IAppState> {
  public constructor() {
    super(createInitialState());
  }
}
